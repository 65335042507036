@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;400&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
//@import url("https://use.typekit.net/cif1lix.css%22");
@import url("https://use.typekit.net/cif1lix.css");

//font-family: neusa-next-std, sans-serif;



@import "custom";
@import "./styles/variables";
@import "./styles/header";
@import "./styles/hero";
@import "./styles/block";
@import "./styles/feature";
@import "./styles/footer";
@import "./styles/work-detail";
@import "./styles/work-list";
@import "./styles/contact";
@import "./styles/agency";
@import "./styles/carousel";
@import "./styles/cursor";
@import "./styles/menu";

*,
*::after,
*::before,
img,
svg,
a,
img {
    box-sizing: border-box;
    // background-color: rgba(red, 0.05);
    // cursor: none !important;
}

body::-webkit-scrollbar {
	width: 8px;
  }
   
  body::-webkit-scrollbar-track {
	
  }
   
  body::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.69)!important;
opacity: .7;
	width: 4px;
height: 4px;

border-radius: 4px;
  }

  body {
	font-family: neuzeit-grotesk, sans-serif;
	
}
  
/*html {
    scroll-behavior: smooth;
    backdrop-filter: blur(214px);
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
}*/

#home-page, #contact-page, #work-detail-page, #work-list-page {
    font-family: neusa-next-std, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: $main-color;
    background-color: #f8f8f8 !important;
    backdrop-filter: blur(214px);
}

// .container-fluid {
//     max-width: 1680px !important;
//     padding-right: 34px !important;
//     padding-left: 34px !important;
//     // overflow-x: hidden;
//     // padding-top: 100px;
// }

//homepage animation fix

#home-page {
     .hero-title span, .hero-subtitle,
     .letter.e, .letter.m, .letter.q {
        opacity: 0;

    }

}
.container {
    position: relative;
    max-width: 1359px !important;
}

progress {
    position: fixed;
    top: 50%;
    right: -20px;
    -webkit-appearance: none;
    appearance: none;
    width: 155px;
    height: 2px;
    border-radius: 2px;
    background-color: #e7e7e7;
    // mix-blend-mode: difference;
    transform: rotate(90deg);
    z-index: 99;
}

progress::-webkit-progress-bar {
    height: 2px;
    border-radius: 2px;
    background: #e7e7e7;
}

progress::-webkit-progress-value {
    height: 2px;
    border-radius: 2px;
    background: #000;
    background-attachment: fixed;
}

progress::-moz-progress-bar {
    height: 2px;
    border-radius: 2px;
    background: #000;
    background-attachment: fixed;
}

.horizontal-slider {
    display: flex;
    height: 100%;
    max-height: 700px;
    padding: 20px 0;
    width: auto;
    padding-top: 50px;
    padding-bottom: 50px;

    .slider-item {
        display: flex;
        position: relative;
        padding-left: 200px;
        padding-right: 200px;
        width: 90%;
        padding-top: 100px;
        padding-bottom: 50px;
        max-width: 100%;
        backdrop-filter: blur(214px);
        background-color: #f8f8f8;

        img {
            width: 800px;
            object-fit: contain;
        }

        p {
            position: absolute;
            top: -20px;
            left: 50%;
            max-width: 90%;
            display: flex;
            transform: translateX(-50%);
            width: 100%;
            font-family: "IBM Plex Mono", monospace;
            $min_font: 32px;
            $max_font: 80px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            mix-blend-mode: difference;
        }
    }
}

.horizontal-slider .slider-item:first-child {
    padding-left: 0;

    p {
        left: 40%;
    }
}

.horizontal-slider div:last-child {
    padding-right: 200px;
    margin-right: 300px;

    p {
        padding-right: 150px;
    }

    img {
        margin-right: 1000px;
    }
}

.slider-title {
    margin-top: 130px;

    .back-link {
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.275em;
        margin-bottom: 50px;
    }
}


.scroll-content {
    padding-bottom: 1px;
    background-color: #f8f8f8 !important;
    backdrop-filter: blur(214px);
}

@media (any-pointer: fine) {
    .cursor {
        max-width: 250px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        opacity: 0.5;
        z-index: 10000;
    }
}

@media (max-width: 1100px) {
    .slider-title {
        margin-top: 273px;

        .back-link {
            margin-bottom: 113px;
        }
    }

}

@media (min-width: 1200px) {
    .container-fluid {
        // max-width: 1680px !important;
        padding-right: 34px !important;
        padding-left: 34px !important;
    }
}

@media (max-width: 1200px) {
    .container-fluid {
        max-width: 100% !important;
        padding-right: 34px !important;
        padding-left: 34px !important;
    }

    progress {
        top: 30%;
        right: -30px;
    }

    .horizontal-slider {
        max-height: 500px;
        padding-bottom: 0;
        padding-top: 150px;
        padding-left: 150px;
        padding-right: 150px;

        .slider-item {
            width: 100%;
            max-width: 600px;
            margin-right: 100px;

            p {
                position: absolute;
                top: 0px;
                left: 50%;
            }

            img {
                width: 400px;
            }
        }
    }
}

@media (max-width: 992px) {
    .slider-title {
        margin-top: 34px;

        .back-link {
            font-size: 14px;
        }
    }

    *,
    *::after,
    *::before {
        box-sizing: border-box;
        cursor: inherit !important;
    }

    nav#ddfullscreenmenu {
        .footer {
            padding-left: 25px;
            padding-right: 25px;
        }

        .header {
            margin-bottom: 80px;
        }
    }

    // .header {
    //     margin-bottom: 100px;
    // }

    progress {
        top: 45%;
        right: -60px;
    }

    .cursor {
        display: none !important;
    }
}



@media (max-width: 576px) {
    .container-fluid {
        max-width: 100% !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .slider-title {
        margin-top: 50px;

        .back-link {
            margin-bottom: 50px;
        }
    }

    .header .header-top {
        padding: 25px;
    }
}

// .cursorActive{
//     display: block;
// }
// .cursorDisable{
//     display: none;
// }
.tilt {
    display: grid;
    place-items: center;
    background-image: radial-gradient(circle at 50%, #fff, #f0f1ff);
    font-family: "Nunito", system-ui, sans-serif;
}

button {
    position: relative;
    width: 232px;
    height: 64px;
    line-height: 64px;
    border: 0;
    background: none;
    color: black;
    font-weight: 700;
    font-size: 1.25rem;
    font-family: inherit;
    cursor: pointer;
}

button svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: -1;
}

#overlayer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    background: #4a4a4a;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 9999;
    border: 4px solid #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: loader 2s infinite ease;
}

.loader-inner {
    vertical-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}
