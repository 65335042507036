@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;400&display=swap");
@import url("https://use.typekit.net/cif1lix.css");
.nav-link {
  display: flex;
}

.agency-subtitle {
  color: #fff !important;
  mix-blend-mode: difference !important;
}

.text-animation-letter {
  display: inline-block;
}

.block-button.active.metis {
  padding: 11px 0;
}

/* ------------------------------------------------------------- *
 * Page transitions
/* ------------------------------------------------------------- */
#page-transition {
  display: none;
}

body.tt-transition #page-transition {
  position: relative;
  display: block;
  z-index: 99999;
}

/* Transition overlay */
body.tt-transition .ptr-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0a0a0a;
  z-index: 1;
}

/* Transition preloader */
body.tt-transition .ptr-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

body.tt-transition .ptr-prel-content {
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  color: #af1212;
}

.ptr-prel-image {
  max-height: 96px;
  /* You may need to change the img height to match your logo type! */
  opacity: 0.2;
  /* You may need to change the opacity as well! */
}

/* ----------------------------------------------------------------------------------------- *
 * Page boxed layout
 * Note: Class "boxed" in <body> tag is connected with class "tt-wrap" in several places!
/* ----------------------------------------------------------------------------------------- */
body.tt-boxed .tt-wrap {
  max-width: 1282px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

body.tt-boxed .tt-wrap .tt-wrap {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

#content-wrap {
  position: relative;
  overflow: hidden;
}

.tt-pci-image-container.work-list-helper-hover-img {
  bottom: initial;
}

.tt-pci-image-container {
  padding: 391px 34px 0 34px;
  display: none;
  bottom: 0;
  position: absolute;
  z-index: 99999;
  background: white;
  width: 100%;
  height: 100vh;
}

.tt-pci-image-full {
  width: 100%;
  max-width: 100%;
  height: 582px;
  object-fit: cover;
}

.work-detail__head {
  display: none;
}

a {
  font-family: neusa-next-std, sans-serif;
  text-decoration: none !important;
}

button:focus,
input:focus,
select:focus {
  outline: none;
}

.text-simple, .project-year p,
.project-category p, .header-top .nav .nav-item .nav-link {
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
}

#tt-header.tt-header-fixed {
  position: fixed;
}

/* header inner */
.tt-header-inner {
  width: 100%;
  display: flex;
  padding-top: 40px;
  align-items: center;
}

header {
  position: relative;
  margin-bottom: 80px;
  z-index: -1;
}
header .logo, header .metis {
  opacity: 0;
}

.header {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.8s ease-in-out;
}
.header .header-top {
  width: 100%;
  padding: 43px 49px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.metis {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: #000000 !important;
}

.metis::before,
.metis::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #000000;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.metis::before {
  content: "";
  transform-origin: 100% 50%;
  transform: scale3d(0, 2, 1);
  transition: transform 0.3s;
}

.metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 2, 1);
}

.header-top .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top .nav .nav-item {
  padding: 5px 21px !important;
}
.header-top .nav .nav-item:last-child {
  padding-right: 0 !important;
}
.header-top .nav .nav-item:first-child {
  padding-left: 0 !important;
}
.header-top .nav .nav-item .active::before {
  transform-origin: 0% -50%;
  transform: scale3d(1, 2, 1);
}
.header-top .nav .nav-item .nav-link {
  padding: 4px 0 !important;
  font-family: neusa-next-std, sans-serif;
  color: #000000;
}

.header .lang {
  margin-left: 120px;
}
.header .lang .nav-item {
  padding: 0 16px 0px 16px !important;
}

@media (max-width: 1100px) {
  .header .header-top {
    padding: 58px 70px;
  }
}
@media (max-width: 768px) {
  .header .header-top {
    padding: 60px 50px;
  }
}
.filter-invert {
  filter: invert(1) !important;
}

.main {
  padding-top: 204px;
}

.hero-title {
  font-family: "IBM Plex Mono", monospace;
  font-size: 100px;
  font-weight: 200;
  line-height: 130px;
  text-align: center;
  margin-bottom: 421px;
  color: #fff;
  mix-blend-mode: difference;
}
.hero-subtitle {
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  text-align: center;
  line-height: 21px;
  letter-spacing: 4.4px;
  font-size: 16px;
  color: #FFFFFF;
  mix-blend-mode: difference;
  margin-bottom: 60px;
}

.letters {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 120px;
  left: 45%;
  transform: translateX(-50%);
}
.letters .letter {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  z-index: 1;
}
.letters .letter img {
  width: 100%;
  max-width: 420px;
  position: relative;
}
.letters .letter::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 650px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  z-index: -1;
}
.letters .q-start {
  margin-right: -50px;
  margin-top: 80px;
}
.letters .m-start {
  margin-left: 65px;
  margin-top: 210px;
}

@media (max-width: 1100px) {
  .main {
    padding-top: 300px;
  }
  .hero-title {
    margin-bottom: 561px;
    padding: 0 50px;
  }
  .letters {
    top: 300px;
  }
  .letters .m-start {
    margin-top: 270px;
  }
}
@media (max-width: 992px) {
  .hero-title {
    padding: 0;
  }
  .letters {
    left: 50%;
  }
  .letters .letter img {
    max-width: 400px;
  }
  .letters .letter::before {
    width: 500px;
    height: 500px;
  }
  .letters .q-start {
    margin-right: 0;
  }
  .letters .m-start {
    margin-left: 0;
    margin-top: 150px;
  }
}
@media (max-width: 768px) {
  .main {
    padding-top: 278px;
  }
  .hero-title {
    font-size: 80px;
    line-height: 104px;
    margin-bottom: 433px;
  }
  .letters .letter img {
    max-width: 250px;
  }
  .letters .letter::before {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 576px) {
  .main {
    padding-top: 200px;
  }
  .hero-title {
    margin-bottom: 100px;
    font-weight: 200;
    font-size: 45px;
    line-height: 60px;
  }
  .letters {
    top: 0;
  }
  .letters .letter::before {
    width: 220px;
    height: 220px;
  }
  .letters .letter img::after {
    width: 200px;
    height: 200px;
  }
}
.block {
  padding-bottom: 100px;
}
.block-row {
  background: #f8f8f8;
  padding-bottom: 5px;
}
.block-row p {
  color: #000000;
}
.block-title {
  flex-direction: column;
  align-items: center;
  mix-blend-mode: difference;
  margin-bottom: 50px;
}
.block-title p {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  line-height: 130px;
  color: #fff;
  margin-bottom: 0;
}
.block-title p {
  font-size: 30px;
}
@media screen and (min-width: 320px) {
  .block-title p {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) {
  .block-title p {
    font-size: 100px;
    line-height: normal;
  }
}
.block-desc {
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  text-align: left;
  color: #000000;
  margin-bottom: 48px;
}
.block-button {
  text-transform: inherit;
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  padding: 4px 0;
}
.block-button:hover {
  color: #fff;
}
.block .active::before {
  transform-origin: 0% -50%;
  transform: scale3d(1, 2, 1);
}

@media (max-width: 1100px) {
  .block-title p {
    line-height: 104px;
  }
  .block-title p {
    font-size: 40px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 320px) {
  .block-title p {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .block-title p {
    font-size: 80px;
    line-height: normal;
  }
}
@media (max-width: 1100px) {
  .block-desc {
    margin-bottom: 37px;
  }
  .block-desc p {
    line-height: 24px;
  }
}
@media (max-width: 1100px) {
  .block .active::before {
    transform: scale3d(0, 2, 1);
  }
}
@media (max-width: 575.98px) {
  .block {
    padding-bottom: 20px;
  }
  .block-title p {
    line-height: 60px;
  }
  .block-desc {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 35px;
  }
  .block-button {
    font-size: 14px;
  }
}
.scroll-slider {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.scroll-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.scroll-slide {
  flex: 1 0 70vw;
  max-width: 70vw;
  height: 100%;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.item-container {
  position: relative;
  display: flex;
  align-items: center;
}
.item-body {
  margin-top: -110px;
}
@media (min-width: 40em) {
  .item-body {
    position: relative;
  }
}

.tag {
  margin-right: 0.5rem;
}
.tag > a {
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.25);
  color: #FFF;
}

footer {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 1px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-bottom: 50px;
}
.footer .nav-link {
  text-align: right;
  color: #000;
  padding: 8px 12px !important;
}
.footer .footer-block {
  display: flex;
  align-items: center;
}
.footer .footer-block ~ .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.footer .footer-block .social-block {
  display: flex;
}
.footer .footer-block .social-block .nav-link:first-child {
  padding-left: 0 !important;
}
.footer .footer-block .social-block .nav-link:last-child {
  padding-right: 0 !important;
}
.footer-copyright {
  text-align: left;
  color: #9d9d9d !important;
  margin: 0;
}
.projects-template .footer-copyright {
  text-align: center;
  display: flex;
  justify-content: center;
  border-top: 1px solid gray;
  padding: 30px 0px;
}

@media (max-width: 1100px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 992px) {
  .footer {
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 25px;
  }
  .footer .footer-block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .footer {
    font-size: 14px;
  }
  .footer .nav-link {
    padding: 8px !important;
  }
  .footer .footer-block {
    flex-direction: column;
    margin-bottom: 0;
  }
  .footer .footer-block ~ .nav-link {
    width: 100%;
    justify-content: center;
  }
  .footer .footer-block .social-block {
    margin-bottom: 15px;
    justify-content: space-around;
    width: 100%;
  }
}
/* ------------------------------------------------------------- *
 * Footer
/* ------------------------------------------------------------- */
#tt-footer {
  position: relative;
  width: 100%;
  z-index: 2;
}

.tt-footer-inner {
  position: relative;
}

/* Footer copyright */
@media (min-width: 1025px) {
  #tt-footer.footer-absolute {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0;
    pointer-events: none;
  }
  #tt-footer.footer-absolute a {
    pointer-events: initial;
  }
  .tt-footer-inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  /* Footer columns */
  .footer-col {
    flex: 1;
    padding: 0 15px;
    margin: 0 5px;
  }
  .footer-col:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .footer-col:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  /* Align footer column content */
  .footer-col.tt-align-top-center {
    display: flex;
    justify-content: center;
  }
  .footer-col.tt-align-top-right {
    display: flex;
    justify-content: flex-end;
  }
  .footer-col.tt-align-center-left {
    display: flex;
    align-items: center;
  }
  .footer-col.tt-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-col.tt-align-center-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .footer-col.tt-align-bottom {
    display: flex;
    align-items: flex-end;
  }
  .footer-col.tt-align-bottom-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .footer-col.tt-align-bottom-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  /* Footer social */
}
/* For smaller screens only
============================ */
@media (max-width: 1024px) {
  .tt-footer-inner {
    display: flex;
    flex-direction: column;
  }
  /* Footer columns */
  .footer-col {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
  /* Order footer columns */
  .footer-col.order-m-first {
    order: -1;
  }
  .footer-col.order-m-last {
    order: 10;
  }
  .footer-col.order-m-1 {
    order: 1;
  }
  .footer-col.order-m-2 {
    order: 2;
  }
  .footer-col.order-m-3 {
    order: 3;
  }
  .footer-col.order-m-4 {
    order: 4;
  }
  .footer-col.order-m-5 {
    order: 5;
  }
  .footer-col.order-m-6 {
    order: 6;
  }
  .footer-col.order-m-7 {
    order: 7;
  }
  .footer-col.order-m-8 {
    order: 8;
  }
  .footer-col.order-m-9 {
    order: 9;
  }
  .footer-col.order-m-10 {
    order: 10;
  }
  /* Footer social */
  #tt-footer .footer-social {
    margin-bottom: 10px;
  }
  #tt-footer .footer-social .footer-social-text {
    display: none;
  }
}
.projects-template .footer-copyright {
  padding: 30px 0px;
  border-top: 1px solid gray;
}
.projects-template .work-detail__section {
  padding-bottom: 0px !important;
}

@media only screen and (max-width: 1100px) {
  .projects-template .footer {
    display: flex;
    align-items: center;
  }
  .projects-template .footer .footer-block {
    justify-content: center;
  }
  .projects-template .footer-copyright {
    display: block;
    margin: 0 auto;
  }
}
.back-link {
  font-family: "IBM Plex Mono", monospace;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
  margin-top: 15px;
  margin-bottom: 100px;
}
.back-link:hover {
  color: #000;
}

#work-detail-page .content {
  padding-top: 14%;
}

.design-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
.design-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media only screen and (min-width: 600px) {
  .design-img img.long-img {
    max-width: 50%;
    margin: auto;
  }
}

.video-img {
  height: 748px;
}

.work-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 580px;
  margin: 0;
}
.work-detail__img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 246px 30px;
  background-color: #fff;
  margin: 0;
  height: 666px;
}
.work-detail__img img {
  max-width: 100%;
}
.work-detail__head {
  position: absolute;
  z-index: 55;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: "IBM Plex Mono", monospace;
  font-size: 80px;
  word-break: break-word;
  font-weight: 200;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
  mix-blend-mode: difference !important;
}
.work-detail__creating {
  padding-top: 140px;
}
.work-detail__creating p {
  font-family: "IBM Plex Mono", monospace;
  font-size: 29px;
  line-height: 47px;
  font-weight: 300;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 0;
}
.work-detail__profession {
  margin-top: 23px;
  font-family: neusa-next-std, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
}
.work-detail__profession-date {
  margin: 0;
  padding-bottom: 44px;
}
.work-detail__profession-list {
  list-style: none;
  margin: 0;
  padding: 0 0 90.5px 0;
}
.work-detail__profession-link {
  display: flex;
  align-items: center;
}
.work-detail__profession-link span {
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: right;
  padding-left: 12px;
  color: #000;
}
.work-detail__info {
  padding: 102px 0 200px 0;
}
.work-detail__info-subtitle {
  margin: 26px 0 35px 0;
  font-family: "IBM Plex Mono", monospace;
  font-size: 29px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.work-detail__info-description {
  max-width: 260px;
  width: 100%;
  font-family: neusa-next-std, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 180%;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.work-detail__section {
  padding-bottom: 100px;
}
@media only screen and (max-width: 700px) {
  .work-detail__section {
    padding-bottom: 50px;
  }
}
.work-detail__title {
  margin-bottom: 50px;
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.275em;
  text-align: center;
}
.work-detail__poster {
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.work-detail__poster-img {
  text-align: center;
}
.work-detail__poster-img img {
  width: 100%;
  object-fit: contain;
  max-width: 512px;
}

.user-avatar {
  max-width: 93px;
  border-radius: 50%;
}
.user-info {
  padding-top: 0;
  padding-bottom: 100px;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
}
.user-info__description {
  margin: 30px auto;
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 187%;
  letter-spacing: normal;
  text-align: center;
}
.user-info__fullname {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-size: 26px;
  line-height: 34px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 5px;
}
.user-info__profession {
  font-family: neusa-next-std, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: 20px;
  line-height: 1.43;
  letter-spacing: normal;
}

.cta {
  background: #fff;
  padding: 210px 0;
  margin-bottom: 100px;
  height: 582px;
}
.cta-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.cta-col p {
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.275em;
  text-align: center;
  margin-bottom: 10px;
}
.cta-col h2 {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-size: 100px;
  line-height: 130px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

@media (max-width: 1100px) {
  #work-detail-page .content {
    padding-top: 368px;
  }
  .work-detail__creating {
    padding-top: 238px;
  }
  .work-detail__profession {
    margin-top: 48px;
  }
  .work-detail__info {
    padding: 163px 0 200px 0;
  }
  .work-detail__poster {
    padding-top: 150px;
    padding-bottom: 150px;
    margin: auto 0;
  }
  .work-detail__poster-img img {
    width: 100%;
    object-fit: contain;
    max-width: 333px;
  }
  .video-img {
    height: 532px;
  }
}
@media (max-width: 992px) {
  #work-detail-page .content {
    padding-top: 168px;
  }
  .back-link {
    margin-bottom: 162px;
  }
  .design-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work-detail {
    height: 300px;
    background-position-x: right;
  }
  .work-detail__head {
    font-size: 70px;
    line-height: 94px;
  }
  .work-detail-link span {
    font-size: 14px;
  }
  .work-detail__title {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .work-detail__poster {
    background-color: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .work-detail__poster img {
    width: 100%;
    max-width: 512px;
  }
  .work-detail__creating {
    padding-top: 76px;
  }
  .work-detail__profession {
    font-size: 14px;
    margin-top: 55px;
  }
  .work-detail__info {
    padding: 131px 0 200px 0;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }
  .work-detail__info-subtitle {
    font-size: 32px;
    margin: 20px 0 30px;
  }
  .work-detail__info-description {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 100px;
  }
  .user-info {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .user-info__description {
    line-height: 1.5;
  }
  .user-info__fullname {
    font-size: 22px;
  }
  .cta {
    padding: 120px 0;
    margin-bottom: 142px;
  }
  .cta h2 {
    font-size: 80px;
    line-height: 104px;
  }
}
@media (max-width: 576px) {
  #work-detail-page .content {
    padding-top: 120px;
  }
  .back-link {
    margin-bottom: 120px;
  }
  .work-detail {
    background-position-x: right;
    height: auto;
    aspect-ratio: 600/445;
  }
  .work-detail__head {
    font-size: 40px;
    line-height: 50px;
    top: -30px;
  }
  .work-detail__creating {
    padding-top: 46px;
  }
  .work-detail__creating p {
    font-size: 28px;
    line-height: 34px;
  }
  .work-detail__info {
    padding: 60px 0 60px;
    max-width: 100%;
  }
  .cta {
    margin-bottom: 80px;
  }
  .cta * {
    color: black;
  }
  .cta h2 {
    font-size: 50px;
    line-height: 65px;
  }
}
.video-img {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.video-img .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-img .bt-play {
  height: 89px;
  width: 89px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  opacity: 1;
  transition: all 0.3s linear;
  mix-blend-mode: difference;
}
.video-img .bt-play img {
  width: 100%;
  height: 100%;
}

.video-img.open .bt-play {
  transform: scale(1.3);
  opacity: 0;
}

.video-img.open .bg-video {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.6s 0.8s;
  transition: all 0.6s 0.8s;
}

.video-img.open .video-container {
  opacity: 1;
  -webkit-transition: all 0.6s 0.8s;
  transition: all 0.6s 0.8s;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 1;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#work-detail-page .container-fluid {
  padding: 0px 25px !important;
}

.work-detail-bgimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .work-detail-bgimg {
    padding: 0px 24px;
  }
}
.work-detail-bgimg img {
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .work-detail-bgimg img {
    object-fit: cover;
    height: 100%;
  }
}

.works {
  display: flex;
  justify-content: center;
  position: relative;
}
.works-title {
  margin-top: 24%;
  margin-bottom: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 4.4px;
  text-align: center;
  color: #000;
}
.works-img {
  width: 674px;
  height: 440px;
  position: fixed;
  left: 50%;
  top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.works-list {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.works-list__title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 39vh;
  mix-blend-mode: difference;
  color: #fff;
  z-index: 9;
  max-width: 570px;
}
.works-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 130px;
}
.works-list a {
  font-size: 24px;
}
@media screen and (min-width: 320px) {
  .works-list a {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) {
  .works-list a {
    font-size: 64px;
    line-height: normal;
  }
}

#work-list .scroller {
  width: 100%;
  max-height: 53.5vh;
  margin-top: 70px;
  margin-bottom: 167px;
  backdrop-filter: blur(214px);
  background-color: #f8f8f8;
}
#work-list .scroller section {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(214px);
  background-color: #f8f8f8;
}
#work-list .scroller section.showcase {
  justify-content: center;
  align-items: unset;
  overflow-y: hidden;
  position: relative !important;
}
#work-list .text-wrap {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#work-list .image-wrap {
  border: none !important;
  position: relative;
  overflow: visible !important;
  max-width: 674px !important;
  width: 100% !important;
  max-height: 440px !important;
  height: 100% !important;
}
#work-list .image {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  max-width: 674px;
  width: 100%;
  max-height: 440px;
  height: 100%;
  background-position: center;
  background-size: cover;
}
#work-list .image img {
  max-width: 100%;
  object-fit: cover;
}
#work-list .pin-spacer {
  position: relative;
  min-width: 280px !important;
  width: 674px !important;
}
#work-list .image.one {
  background-image: url("../../assets/img/portfolio/carousel/portf-carousel-1.jpeg");
}
#work-list .image.two {
  background-image: url("https://images.unsplash.com/photo-1519378058457-4c29a0a2efac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=648&q=80");
}
#work-list .image.three {
  background-image: url("https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
}
#work-list .image.four {
  background-image: url("../../assets/img/work-list.png");
}
#work-list .image.five {
  background-image: url("https://images.unsplash.com/photo-1558350315-8aa00e8e4590?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
}
#work-list .image.six {
  background-image: url("../../assets/img/work-list.png");
}
#work-list .image.seven {
  background-image: url("https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
}
#work-list .image.eight {
  background-image: url("../../assets/img/work-list.png");
}
#work-list .scrollbar-track {
  display: none !important;
}

@media (max-width: 1200px) {
  .works-list {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 992px) {
  #work-list .scroller {
    max-height: 42vh;
    width: 100%;
  }
  #work-list .scroller section.showcase {
    height: 740vh;
  }
  #work-list .image {
    max-width: 400px;
    max-height: 250px;
  }
  #work-list .pin-spacer {
    min-width: 400px !important;
    width: 400px !important;
  }
  .works-title {
    padding: 0;
  }
  .works-title a {
    margin-bottom: 150px;
  }
  .works-img {
    width: 450px;
    height: 250px;
  }
}
@media (max-width: 757.98px) {
  .works-img {
    width: 300px;
    height: 180px;
  }
  #work-list .image {
    max-width: 250px;
    max-height: 150px;
  }
  #work-list .pin-spacer {
    min-width: 250px !important;
    width: 250px !important;
  }
}
.contact {
  margin-bottom: 200px;
  position: relative;
}
.contact-mobile {
  display: none;
}
.contact-bg {
  position: absolute;
  width: 47em;
  object-fit: contain;
  display: block;
  left: 49%;
  transform: translate(-50%, 0);
  width: 50%;
  margin-top: -2%;
}
.contact-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact .link::before {
  position: absolute;
  height: 8px;
  background: #000000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  content: "";
  transform-origin: 0% 50%;
  width: 626px;
}
.contact .link {
  cursor: pointer;
  position: relative;
  min-height: 130px;
  transition: all 0.4s linear;
  margin-bottom: 51px;
}
.contact .link:last-child {
  margin-bottom: 0;
}
.contact .link h3 {
  max-width: 100%;
  display: flex;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  line-height: normal;
  text-align: center;
  transition: all 0.5s ease-in-out;
  font-size: 90px;
  font-size: 72px;
  justify-content: center;
  align-items: center;
}
.contact .link span {
  transition: 0.3s linear;
}
.contact .link .normal {
  transform: scale(1);
  opacity: 1;
}
.contact .link .hover {
  transform: scale(0.9);
  opacity: 0;
}
.contact .link:hover .normal {
  transform: scale(0.5);
  opacity: 0;
}
.contact .link:hover .hover {
  transform: scale(1);
  opacity: 1;
}
.contact .inactive {
  opacity: 0.1;
}
.contact .linked::before {
  width: 361px;
}
.contact a {
  font-family: "IBM Plex Mono", monospace;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  color: #000000;
  /*		-webkit-mix-blend-mode: difference;
  		-moz-mix-blend-mode: difference;
  		-o-mix-blend-mode: difference;
  		-ms-mix-blend-mode: difference;
  		mix-blend-mode: difference;*/
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.row-title {
  position: relative;
  mix-blend-mode: difference;
  z-index: 99;
  padding-top: 176px;
}
.row-title h5,
.row-title h4 {
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.275em;
  text-align: center;
  font-family: "IBM Plex Mono", monospace;
  margin-bottom: 3%;
  color: #fff;
}
.row-title h4 {
  margin-top: 200px;
  margin-bottom: 73px;
}

.social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 230px;
}
.social-links a {
  font-family: "IBM Plex Mono", monospace;
  font-size: 70px;
  font-size: 56px;
  font-weight: 200;
  line-height: 1.03;
  text-align: center;
  color: #000;
  margin-bottom: 36px;
}
.social-links a :hover {
  color: #000;
}
.social-links a:last-child {
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .contact .link h3 {
    font-size: 83px;
    font-size: 67px;
  }
}
@media (max-width: 1100px) {
  .row-title {
    position: sticky;
    top: 250px;
    padding-top: 0;
  }
  .row-title h5 {
    margin-bottom: 69px;
  }
  .row-title h4 {
    margin-bottom: 94px;
  }
  .contact {
    margin-bottom: 100px;
    margin-top: 250px;
  }
  .contact-desktop {
    display: none;
  }
  .contact-mobile {
    position: relative;
    display: block;
  }
  .contact-bg_block {
    display: block;
    text-align: center;
    margin: auto;
    position: sticky;
    top: 340px;
    max-width: max-content;
  }
  .contact-bg_block img {
    max-width: 100%;
  }
  .contact-bg_block :first-child {
    opacity: 0.5;
  }
  .contact-bg_block :last-child {
    position: absolute;
    top: 0;
    left: 0;
  }
  .contact .link {
    margin-top: 226px;
    margin-bottom: 0;
  }
  .contact .link:first-child {
    margin-top: -231px;
    padding: 0 65px;
  }
  .contact .link h3 {
    display: flex;
    flex-direction: column;
    opacity: 1;
    font-size: 80px;
    font-size: 68px;
  }
  .contact .link h3 .hover {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 39px;
  }
  .contact .link h3 .normal {
    line-height: 104px;
    margin-bottom: 39px;
  }
  .contact .link h3 .hover,
.contact .link h3 .normal {
    opacity: 1;
    transform: scale(1);
  }
  .contact .link .inactive {
    opacity: 1;
  }
  .contact .link .hover {
    display: flex;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 39px;
  }
  .contact .link:hover .normal,
.contact .link:hover .hover {
    opacity: 1;
    transform: scale(1);
  }
  .contact .link::before {
    width: 261px;
  }
  .contact .linked::before {
    width: 161px;
  }
  .social-links {
    margin-bottom: 280px;
  }
}
@media (max-width: 768px) {
  .contact-bg {
    display: none;
  }
  .contact .link h3.normal {
    font-size: 80px;
    font-size: 67px;
    line-height: 104px;
  }
  .contact .link:first-child {
    padding: 0;
  }
  .row-title {
    top: 250px;
  }
  .row-title h5 {
    margin-bottom: 45px;
  }
  .row-title h4 {
    margin-bottom: 54px;
  }
  .social-links a {
    font-size: 40px;
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .row-title {
    top: 150px;
  }
  .row-title h5 {
    margin-bottom: 45px;
  }
  .row-title h4 {
    margin-bottom: 54px;
  }
  .contact {
    margin-top: 67%;
  }
  .contact-bg_block {
    top: 250px;
  }
  .contact .link {
    margin-top: 120px;
  }
  .contact .link:first-child {
    margin-top: -150px;
  }
  .contact .link:first-child {
    padding: 0;
  }
  .contact .link h3 .normal {
    font-size: 52px;
    font-size: 45px;
    line-height: normal;
  }
  .contact .link h3 .hover,
.contact .link h3 .normal {
    margin-bottom: 35px;
  }
  .contact .link::before {
    height: 3px;
  }
}
.circle-wrapper {
  padding-top: 176px;
  position: relative;
  overflow: hidden;
}
.circle-svg {
  width: 1266px;
  height: 1266px;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
}
.circle-content {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: visible;
}
.circle-content p {
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  margin-bottom: 73px;
}
.circle-content h1 {
  font-family: "IBM Plex Mono", monospace;
  font-size: 100px;
  line-height: 130px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 102px;
}
.circle-content h2 {
  text-align: initial;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}

@keyframes opacityAnim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.philosophie {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: opacityAnim 3.3s ease-in-out 1 forwards;
}
.philosophie .wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
}

.agency {
  display: block;
}
.agency-title {
  text-align: center;
}
.agency-text {
  margin-bottom: 200px;
}
.agency-text p {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000;
}
.agency-slider {
  position: relative;
  margin-bottom: 53px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agency-slider__img {
  height: 550px;
}
.agency-slider__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.agency-slider__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 595px;
}
.agency-slider__content span {
  font-size: 16px;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
}
.agency-slider__title {
  font-size: 50px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -2.4px;
  margin-bottom: 20px;
  text-align: center;
  color: #000000;
  font-family: "IBM Plex Mono", monospace;
}
.agency-slider__subtitle {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.88;
  text-align: center;
  margin-bottom: 120px;
  color: #000000;
}
.agency-content {
  padding-top: 100px;
}
.agency-content h4 {
  font-family: "IBM Plex Mono", monospace;
  font-size: 29px;
  font-weight: 300;
  line-height: normal;
  margin: 30px 0;
}
.agency-content p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.88;
  letter-spacing: normal;
  margin-bottom: 0;
  max-width: 243px;
}
.agency-content__first {
  margin-bottom: 228px;
}
.agency-content__last {
  margin-top: 10px;
  margin-bottom: 300px;
}

.tablet-visible {
  display: none;
}

@media (max-width: 1100px) {
  .tablet-hidden {
    display: none;
  }
  .tablet-visible {
    display: block;
  }
  .agency-content {
    width: 70%;
  }
  .agency-content__first {
    margin-bottom: 100px;
  }
  .agency-content__last {
    margin-top: 100px;
    margin-bottom: 232px;
  }
  .agency-slider__subtitle {
    margin-bottom: 126px;
  }
  .agency .circle-wrapper {
    padding-top: 250px;
  }
  .agency .circle-svg {
    top: -28px;
    left: 50.5%;
  }
  .agency .circle-content {
    margin-bottom: 126px;
  }
  .agency .circle-content p {
    margin-bottom: 109px;
  }
  .agency .circle-content h1 {
    margin-bottom: 393px;
  }
}
@media (max-width: 992px) {
  .circle-wrapper {
    padding-top: 250px;
  }
  .circle-svg {
    width: 110%;
    height: 70%;
    object-fit: contain;
    top: 12%;
    left: 50%;
  }
  .circle-content p {
    margin-bottom: 69px;
  }
  .circle-content h1 {
    font-size: 75px;
    line-height: 104px;
    margin-bottom: 277px;
  }
  .agency-slider {
    padding: 0;
    margin-bottom: 76px;
  }
  .agency-title {
    margin-bottom: 100px;
  }
  .agency-subtitle {
    margin-bottom: 300px;
  }
  .agency-text {
    display: flex;
    justify-content: center;
  }
  .agency-content__last {
    margin-bottom: 174px;
  }
}
@media (max-width: 900px) {
  .circle-wrapper {
    padding-top: 180px;
  }
  .circle-svg {
    height: 53%;
  }
  .circle-content h1 {
    font-size: 50px;
    line-height: 80px;
    margin-bottom: 180px;
  }
  .agency-content {
    width: 90%;
  }
  .agency-content__last {
    margin-bottom: 120px;
  }
  .agency-slider {
    padding: 0;
    margin-bottom: 76px;
  }
  .agency-slider__content {
    max-width: 373px;
  }
  .agency-slider__title {
    font-size: 58px;
    line-height: 58px;
    letter-spacing: -0.03em;
    margin-bottom: 20px;
  }
  .agency-slider__subtitle {
    margin-bottom: 137px;
  }
}
@media (max-width: 576px) {
  .agency-content {
    width: 100%;
  }
  .agency-slider {
    margin-bottom: 0;
  }
  .agency-slider__content {
    max-width: 320px;
  }
  .agency-slider__title {
    font-size: 30px;
  }
  .agency .circle-content h1 {
    font-size: 45px;
    line-height: 70px;
  }
}
/* ------------------------------------------------------------- *
 * Portfolio carousel
/* ------------------------------------------------------------- */
.tt-portfolio-carousel {
  position: relative;
  margin-bottom: 65px;
  overflow: hidden;
}

.tt-portfolio-carousel .swiper-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  height: fit-content;
}

.tt-portfolio-carousel .swiper-wrapper.swtr-smooth {
  /* Add transition when windows resize or orientation change */
  transition: transform 0.8s !important;
}

.tt-portfolio-carousel .swiper-slide {
  height: auto;
  background-color: #f8f8f8 !important;
}

@media (min-width: 1025px) {
  .tt-portfolio-carousel .swiper-slide {
    width: auto;
  }
}
/* Portfolio carousel item
=========================== */
.tt-portfolio-carousel-item {
  position: relative;
  display: flex;
  line-height: 0;
}

.project-year,
.project-category {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.project-year p,
.project-category p {
  width: max-content;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.project-year {
  left: -7%;
}

.project-category {
  right: -18%;
}

@media (min-width: 1025px) {
  .tt-portfolio-carousel-item {
    margin-left: 7vw;
    margin-right: 7vw;
  }
}
.tt-pci-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
  position: relative;
}

video.tt-pci-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
  height: 440px;
}

.tt-pci-image {
  width: auto;
}

.tt-portfolio-carousel-item .tt-pci-video {
  width: 48vw;
}

/* Portfolio carousel item hover
================================= */
@media (min-width: 1025px) {
  .pci-hover .tt-slide-active .tt-portfolio-carousel-item .tt-pci-image-wrap,
.pci-hover .tt-slide-active .tt-portfolio-carousel-item .tt-pci-caption-front {
    transition: all 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .pci-hover .tt-pci-image {
    transition: all 1s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .pci-hover .tt-portfolio-carousel-item:hover .tt-pci-image {
    transform: scale(1.05);
  }
}
/* Slide disabled (for non active slides)
================== */
.tt-portfolio-carousel-item {
  transition: all 0.5s ease-in-out;
}
.tt-portfolio-carousel-item .tt-pci-caption {
  opacity: 1;
}

.swiper-slide.tt-pcs-disabled .tt-portfolio-carousel-item {
  cursor: auto;
  pointer-events: none;
  transform: scale(0.9);
}

/* Portfolio carousel item caption
=================================== */
.tt-pci-caption-inner {
  position: absolute;
  min-width: 120%;
  left: 50%;
  top: 27%;
  z-index: 9;
  transform: translate3d(-50%, -50%, 0);
  mix-blend-mode: difference;
  height: 200px;
}
@media only screen and (max-width: 600px) {
  .tt-pci-caption-inner {
    top: 58%;
  }
}

.tt-pci-caption {
  position: relative;
}

.tt-pci-title {
  font-family: "IBM Plex Mono", monospace;
  font-size: 100px;
  font-weight: 200;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  mix-blend-mode: difference !important;
  /*background: linear-gradient(to right, #8fa7a6 20%, #464646 40%, #8fa7a6, #464646 80%);
      text-shadow: 2px 4px 4px rgb(0 0 0 / 20%), 0px -5px 10px rgb(255 255 255 / 15%);
      background-size: 200% auto;
      color: #000;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradient 15s linear infinite;
  */
}
.tt-pci-title a {
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
  mix-blend-mode: difference;
  color: #000;
  text-align: center;
}
.tt-pci-title a:hover {
  color: #000;
}

.tt-pci-categories {
  position: relative;
  margin-top: 30px;
}

.tt-pci-category {
  position: relative;
  display: inline-block;
  font-size: calc(13px + 0.1vw);
  color: #000;
  line-height: 1.2;
}

.tt-pci-category ~ .tt-pci-category {
  margin-left: -4px;
}

.tt-pci-category ~ .tt-pci-category:not(:empty)::before {
  content: ", ";
}

.tt-pci-category ~ .tt-pci-category ~ .tt-pci-category ~ .tt-pci-category {
  visibility: hidden;
  width: 0;
}

.tt-pci-category ~ .tt-pci-category ~ .tt-pci-category ~ .tt-pci-category ~ .tt-pci-category {
  display: none;
}

.tt-pci-category + .tt-pci-category + .tt-pci-category + .tt-pci-category::before {
  content: "...";
  margin-left: 5px;
  visibility: visible;
}

/* Portfolio carousel caption animation
======================================== */
.swiper-slide .tt-pci-caption-inner,
.swiper-slide .tt-pci-categories,
.swiper-slide .project-category,
.swiper-slide .project-year {
  opacity: 0;
  visibility: hidden;
  transition: 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.swiper-slide.swiper-slide-prev .tt-pci-caption-inner,
.swiper-slide.swiper-slide-next .tt-pci-caption-inner,
.swiper-slide.swiper-slide-prev .tt-pci-categories,
.swiper-slide.swiper-slide-next .project-category,
.swiper-slide.swiper-slide-next .project-year {
  transition: all 0.3s ease-out;
}

.swiper-slide.swiper-slide-active .tt-pci-caption-inner {
  opacity: 1;
  visibility: visible;
}

.swiper-slide.swiper-slide-active .tt-pci-categories,
.swiper-slide.swiper-slide-active .project-category,
.swiper-slide.swiper-slide-active .project-year {
  opacity: 1;
  visibility: visible;
  transform: translateX(0), translateY(0);
}

/* Offset */
.swiper-slide.swiper-slide-prev .tt-pci-caption-inner {
  transform: translate3d(100px, -50%, 0) skewX(10deg);
}

.swiper-slide.swiper-slide-prev .project-category,
.swiper-slide.swiper-slide-prev .project-year {
  transform: translate3d(0, -100px, 0) skewY(10deg);
}

.swiper-slide.swiper-slide-next .tt-pci-caption-inner {
  transform: translate3d(100px, -50%, 0) skewX(-10deg);
}

.swiper-slide.swiper-slide-next .project-category,
.swiper-slide.swiper-slide-next .project-year {
  transform: translate3d(0, 100px, 0) skewY(-10deg);
}

.swiper-slide.swiper-slide-prev .tt-pci-categories {
  transform: translate3d(-30px, 0, 0);
}

.swiper-slide.swiper-slide-next .tt-pci-categories {
  transform: translate3d(30px, 0, 0);
}

/* Delay */
.swiper-slide.swiper-slide-active .tt-pci-caption-inner,
.swiper-slide.swiper-slide-active .project-category,
.swiper-slide.swiper-slide-active .project-year {
  transition-delay: 0.6s;
}

.swiper-slide.swiper-slide-active .tt-pci-categories {
  transition-delay: 0.7s;
}

.swiper-slide.tt-slide-active {
  background-color: #f8f8f8 !important;
}

/* Portfolio carousel caption position
======================================= */
/* Position center */
.pci-caption-center .tt-pci-caption {
  top: 50%;
  left: 50%;
  padding: 0 5%;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
}

.pci-caption-center .tt-pci-caption-back {
  display: none !important;
}

/* Portfolio carousel navigation
================================= */
.tt-pc-navigation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  width: 300px;
  height: 20px;
  z-index: 1;
  transform: translate3d(-50%, 0, 0);
  margin-top: 37px;
}

.tt-pc-nav-prev,
.tt-pc-nav-next {
  position: absolute;
  top: 0;
  z-index: 20;
}

.tt-pc-nav-prev {
  left: 0;
}

.tt-pc-nav-next {
  right: 0;
}

.tt-pc-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  outline: none;
  max-height: 20px;
}

.tt-pc-arrow-prev::after,
.tt-pc-arrow-next::after {
  line-height: 0;
  font-weight: 900;
  display: flex;
  align-items: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.tt-pc-arrow-prev::after {
  content: "";
  background-image: url(../../assets/img/slider-arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 111px;
  height: 20px;
  transform: rotate(-180deg);
}

.tt-pc-arrow-next::after {
  content: "";
  background-image: url(../../assets/img/slider-arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 111px;
  height: 20px;
}

.tt-pc-arrow.tt-pc-arrow-disabled {
  cursor: auto;
  pointer-events: none;
}

.tt-portfolio-carousel .swiper-button-lock {
  display: none;
}

/* Portfolio carousel pagination
================================= */
/* Portfolio carousel pagination bullets */
.tt-pc-pagination-bullets {
  font-size: 0;
}

.tt-pc-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 8px;
  opacity: 1;
  border-radius: 100%;
  border: 2px solid #000;
}

.tt-pc-pagination-bullets .swiper-pagination-bullet-active {
  background: #000;
  opacity: 1;
}

/* Portfolio carousel pagination dynamic bullets */
.tt-pc-pagination-bullets-dynamic {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
  transition: 0.2s transform, 0.2s left;
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.tt-pc-pagination-fraction {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 1;
}

.tt-pc-pagination-progressbar {
  position: absolute;
  left: 70px;
  right: 70px;
  width: auto;
  max-width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.tt-pc-pagination-progressbar .swiper-pagination-progressbar-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  transform: scale(0);
  transform-origin: left top;
}

@media (max-width: 1439px) {
  .project-year {
    left: -6%;
  }
  .project-category {
    right: -12%;
  }
}
@media (max-width: 1100px) {
  .tt-pci-title {
    font-size: 65px;
    max-width: 110%;
  }
  .tt-pci-caption-back {
    display: none;
  }
  .tt-pci-caption-front {
    display: none;
  }
  .tt-portfolio-carousel {
    margin-bottom: 260px;
  }
  .tt-portfolio-carousel .swiper-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    overflow: visible;
  }
  .project {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: fit-content;
    width: 100%;
    margin-top: 30px;
  }
  .project-category, .project-year {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0);
  }
  .project-year {
    margin-bottom: 25px;
  }
  .tt-pc-navigation {
    position: absolute;
    width: 60%;
    bottom: 15%;
    margin-top: 0;
  }
  .tt-portfolio-carousel .swiper-wrapper {
    height: 65vh;
  }
}
@media (max-width: 768px) {
  .tt-portfolio-carousel .swiper-wrapper {
    height: 50vh;
  }
  .tt-portfolio-carousel {
    margin-bottom: 180px;
  }
  .tt-pci-title {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .tt-pci-title {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (max-width: 768px) and (min-width: 768px) {
  .tt-pci-title {
    font-size: 70px;
    line-height: normal;
  }
}
@media (max-width: 768px) {
  .tt-portfolio-carousel .swiper-slide {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .tt-pc-navigation {
    bottom: 16%;
    width: 90%;
  }
}
@media (max-width: 768px) {
  .tt-pc-arrow-next:after,
.tt-pc-arrow-prev:after {
    width: 80px;
  }
}
@media (max-width: 768px) {
  .tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
    height: 40vw;
  }
}
@media (max-width: 576px) {
  .tt-portfolio-carousel {
    margin-bottom: 80px;
  }
  .tt-pc-navigation {
    bottom: 16%;
  }
}
.tt-portfolio-carousel .swiper-slide {
  margin-top: -60px;
  padding-top: 60px;
}
.tt-portfolio-carousel .tt-pci-caption-inner {
  margin-top: -102px;
}

@media (max-width: 770px) {
  .tt-portfolio-carousel {
    /*
      .swiper-slide:nth-child(2n) {
    	width: 20% !important;
      }

      .swiper-slide:nth-child(3n) {
    	width: 20% !important;
      }
      */
  }
  .tt-portfolio-carousel .swiper-wrapper {
    width: 100%;
    padding-top: 100px;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .tt-portfolio-carousel .swiper-slide {
    margin-top: -70px;
    padding-top: 70px;
  }
  .tt-portfolio-carousel .tt-pci-caption-inner {
    min-width: fit-content;
    max-width: 100%;
    width: 100%;
    margin-top: -9%;
  }
  .tt-portfolio-carousel .tt-pci-title {
    font-size: 33px;
    line-height: normal;
    margin-top: -3px;
  }
  .tt-portfolio-carousel .swiper {
    width: 100%;
  }
  .tt-portfolio-carousel .swiper-slide {
    width: 73% !important;
  }
}
/* ------------------------------------------------------------- *
 * Smooth Scrollbar
 * Source: https://github.com/idiotWu/smooth-scrollbar/
/* ------------------------------------------------------------- */
/* Hide default scrollbar */
body:not(.is-mobile).tt-smooth-scroll {
  overflow: hidden;
}

/* Scroll content (added if <body> contain class "tt-smooth-scroll". Disabled on mobile devices!) */
body:not(.is-mobile).tt-smooth-scroll #scroll-container {
  position: relative;
  width: auto;
  height: 100vh;
  overflow: auto;
  margin: 0;
  background-color: #f8f8f8;
}

/* Styling scrollbar */
.scrollbar-track {
  background: transparent !important;
  z-index: 99999 !important;
}

.scrollbar-track:hover {
  background: rgba(222, 222, 222, 0.7) !important;
}

.scrollbar-thumb {
  background: #000 !important;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.scrollbar-track:hover .scrollbar-thumb {
  opacity: 1;
}

/* ------------------------------------------------------------- *
 * Magic cursor
/* ------------------------------------------------------------- */
#magic-cursor {
  position: absolute;
  display: none;
  width: 220px;
  height: 220px;
  max-width: 250px;
  pointer-events: none;
  z-index: 99999;
  opacity: 0;
}

body.tt-magic-cursor #magic-cursor {
  display: block;
}

#ball {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  max-width: 250px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  /* Note: border width handled through JS */
}

/* Ball view 
============= */
#ball .ball-view {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  line-height: 1.3;
  text-align: center;
  transform: scale(0);
}

#ball .ball-view i {
  font-size: 19px;
}

/* Ball drag 
============= */
#ball .ball-drag {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 600;
  color: #FFF;
  line-height: 1.2;
  text-align: center;
  transition: all 0.3s;
}

#ball .ball-drag::before,
#ball .ball-drag::after {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  font-size: 19px;
  color: #FFF;
  height: 10px;
  line-height: 10px;
  /* Font Awesome */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#ball .ball-drag::before {
  content: "\f104";
  /* Font Awesome */
  left: 0;
  transform: translate3d(-30px, 0, 0);
  transition: all 0.25s;
}

#ball .ball-drag::after {
  content: "\f105";
  /* Font Awesome */
  right: 0;
  transform: translate3d(30px, 0, 0);
  transition: all 0.25s;
}

/* Ball close 
============== */
#ball.ball-close-enabled {
  opacity: 1 !important;
}

#ball .ball-close {
  position: absolute;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  line-height: 1;
  text-align: center;
}

body {
  -webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}
nav#ddfullscreenmenu {
  display: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  backdrop-filter: blur(214px);
  background-color: #f8f8f8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
  -webkit-transition: visibility 0s 0.5s, opacity 0.5s, -webkit-transform 0.5s;
  transition: visibility 0s 0.5s, opacity 0.5s, transform 0.5s;
}
@media screen and (min-width: 1020px) {
  nav#ddfullscreenmenu {
    display: none !important;
  }
}
nav#ddfullscreenmenu.openmenu {
  height: 100vh;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0s;
  -transition-delay: 0s;
}
nav#ddfullscreenmenu .footer {
  padding-left: 50px;
  padding-right: 50px;
}

#navtoggler,
#closex {
  display: flex;
  max-width: 76px;
  max-height: 76px;
  position: absolute;
  cursor: pointer;
  z-index: 15;
  right: 75px;
  top: 65px;
}

#logo {
  position: relative;
  display: flex;
  margin: 60px 50px 0;
}
#logo img {
  width: 301px;
}

div#ulwrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 15px;
  margin-top: 100px;
}

nav#ddfullscreenmenu ul {
  backdrop-filter: blur(214px);
  background-color: #f8f8f8;
  list-style: none;
  margin: 0;
  padding: 50px 0px;
  position: relative;
  width: 100%;
  max-height: 100%;
  font: 500 22px "acumin-pro-wide", sans-serif;
  -webkit-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}
nav#ddfullscreenmenu ul li {
  margin: 0;
  padding: 15px 0;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
}

#fullscreenmenu-ul li .active::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 2, 1);
}
#fullscreenmenu-ul li a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
#fullscreenmenu-ul li a {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  #fullscreenmenu-ul li a {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) {
  #fullscreenmenu-ul li a {
    font-size: 60px;
    line-height: normal;
  }
}

nav#ddfullscreenmenu .menu-copyright {
  font-family: neusa-next-std, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #9d9d9d;
  margin-bottom: 0;
}

nav#ddfullscreenmenu .lang .nav-item {
  padding: 0 16px !important;
}
nav#ddfullscreenmenu .lang li .active::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 2, 1);
}
nav#ddfullscreenmenu .lang li a {
  font-family: neusa-next-std, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

nav#ddfullscreenmenu ul .hidemenu {
  transform: rotateY(30deg) translateZ(-5px) !important;
  opacity: 0;
  pointer-events: none;
}
nav#ddfullscreenmenu ul .showmenu {
  transform: translate3d(0, 0, 0) !important;
  opacity: 1 !important;
  pointer-events: auto;
}

nav#ddfullscreenmenu .lang-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
nav#ddfullscreenmenu .lang-checkbox input {
  appearance: none;
  padding: 0 10px;
}
nav#ddfullscreenmenu .lang-checkbox .active {
  color: #000000;
}

#footermenu {
  align-items: flex-start;
  flex-direction: column;
}
#footermenu .nav-link {
  padding-left: 0 !important;
}
#footermenu .footer-block {
  width: 100%;
  justify-content: space-between;
}

#closex img,
#navtoggler img {
  max-width: 76px;
}

#navtoggler,
#closex {
  right: 71px;
  top: 71px;
}

@media (max-width: 576px) {
  #logo {
    margin: 25px 25px 0;
  }
  #logo img {
    width: 215px;
  }
  #navtoggler,
#closex {
    right: 25px;
    top: 25px;
  }
  #navtoggler img,
#closex img {
    max-width: 45px;
  }
  div#ulwrapper {
    margin-top: 50px;
  }
  nav#ddfullscreenmenu ul {
    font-size: 16px;
  }
  nav#ddfullscreenmenu .lang .nav-link {
    padding: 6px !important;
  }
}
*,
*::after,
*::before,
img,
svg,
a,
img {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.69) !important;
  opacity: 0.7;
  width: 4px;
  height: 4px;
  border-radius: 4px;
}

body {
  font-family: neuzeit-grotesk, sans-serif;
}

/*html {
    scroll-behavior: smooth;
    backdrop-filter: blur(214px);
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
}*/
#home-page, #contact-page, #work-detail-page, #work-list-page {
  font-family: neusa-next-std, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #000;
  background-color: #f8f8f8 !important;
  backdrop-filter: blur(214px);
}

#home-page .hero-title span, #home-page .hero-subtitle,
#home-page .letter.e, #home-page .letter.m, #home-page .letter.q {
  opacity: 0;
}

.container {
  position: relative;
  max-width: 1359px !important;
}

progress {
  position: fixed;
  top: 50%;
  right: -20px;
  -webkit-appearance: none;
  appearance: none;
  width: 155px;
  height: 2px;
  border-radius: 2px;
  background-color: #e7e7e7;
  transform: rotate(90deg);
  z-index: 99;
}

progress::-webkit-progress-bar {
  height: 2px;
  border-radius: 2px;
  background: #e7e7e7;
}

progress::-webkit-progress-value {
  height: 2px;
  border-radius: 2px;
  background: #000;
  background-attachment: fixed;
}

progress::-moz-progress-bar {
  height: 2px;
  border-radius: 2px;
  background: #000;
  background-attachment: fixed;
}

.horizontal-slider {
  display: flex;
  height: 100%;
  max-height: 700px;
  padding: 20px 0;
  width: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.horizontal-slider .slider-item {
  display: flex;
  position: relative;
  padding-left: 200px;
  padding-right: 200px;
  width: 90%;
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 100%;
  backdrop-filter: blur(214px);
  background-color: #f8f8f8;
}
.horizontal-slider .slider-item img {
  width: 800px;
  object-fit: contain;
}
.horizontal-slider .slider-item p {
  position: absolute;
  top: -20px;
  left: 50%;
  max-width: 90%;
  display: flex;
  transform: translateX(-50%);
  width: 100%;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  mix-blend-mode: difference;
}
.horizontal-slider .slider-item p {
  font-size: 32px;
}
@media screen and (min-width: 320px) {
  .horizontal-slider .slider-item p {
    font-size: 35px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) {
  .horizontal-slider .slider-item p {
    font-size: 80px;
    line-height: normal;
  }
}

.horizontal-slider .slider-item:first-child {
  padding-left: 0;
}
.horizontal-slider .slider-item:first-child p {
  left: 40%;
}

.horizontal-slider div:last-child {
  padding-right: 200px;
  margin-right: 300px;
}
.horizontal-slider div:last-child p {
  padding-right: 150px;
}
.horizontal-slider div:last-child img {
  margin-right: 1000px;
}

.slider-title {
  margin-top: 130px;
}
.slider-title .back-link {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.275em;
  margin-bottom: 50px;
}

.scroll-content {
  padding-bottom: 1px;
  background-color: #f8f8f8 !important;
  backdrop-filter: blur(214px);
}

@media (any-pointer: fine) {
  .cursor {
    max-width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
    z-index: 10000;
  }
}
@media (max-width: 1100px) {
  .slider-title {
    margin-top: 273px;
  }
  .slider-title .back-link {
    margin-bottom: 113px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 34px !important;
    padding-left: 34px !important;
  }
}
@media (max-width: 1200px) {
  .container-fluid {
    max-width: 100% !important;
    padding-right: 34px !important;
    padding-left: 34px !important;
  }
  progress {
    top: 30%;
    right: -30px;
  }
  .horizontal-slider {
    max-height: 500px;
    padding-bottom: 0;
    padding-top: 150px;
    padding-left: 150px;
    padding-right: 150px;
  }
  .horizontal-slider .slider-item {
    width: 100%;
    max-width: 600px;
    margin-right: 100px;
  }
  .horizontal-slider .slider-item p {
    position: absolute;
    top: 0px;
    left: 50%;
  }
  .horizontal-slider .slider-item img {
    width: 400px;
  }
}
@media (max-width: 992px) {
  .slider-title {
    margin-top: 34px;
  }
  .slider-title .back-link {
    font-size: 14px;
  }
  *,
*::after,
*::before {
    box-sizing: border-box;
    cursor: inherit !important;
  }
  nav#ddfullscreenmenu .footer {
    padding-left: 25px;
    padding-right: 25px;
  }
  nav#ddfullscreenmenu .header {
    margin-bottom: 80px;
  }
  progress {
    top: 45%;
    right: -60px;
  }
  .cursor {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .container-fluid {
    max-width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .slider-title {
    margin-top: 50px;
  }
  .slider-title .back-link {
    margin-bottom: 50px;
  }
  .header .header-top {
    padding: 25px;
  }
}
.tilt {
  display: grid;
  place-items: center;
  background-image: radial-gradient(circle at 50%, #fff, #f0f1ff);
  font-family: "Nunito", system-ui, sans-serif;
}

button {
  position: relative;
  width: 232px;
  height: 64px;
  line-height: 64px;
  border: 0;
  background: none;
  color: black;
  font-weight: 700;
  font-size: 1.25rem;
  font-family: inherit;
  cursor: pointer;
}

button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: -1;
}

#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: #4a4a4a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 9999;
  border: 4px solid #fff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}