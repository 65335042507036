.contact {
	//margin-top: 176px;
	// padding-top: 176px;
	// margin-top: 270px;
	margin-bottom: 200px;
	position: relative;

	&-mobile {
		display: none;
	}

	&-bg {
		position: absolute;
		width: 47em;
		object-fit: contain;
		display: block;
		//top: 52%;
		left: 49%;
		transform: translate(-50%, 0);
		
    width: 50%;
    
    //margin-top: 130px;
	margin-top: -2%;
	}

	&-link {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
.link{
	&::before {
		position: absolute;
		height: 8px;
		background: #000000;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
		content: "";
		transform-origin: 0% 50%;
		width: 626px;
	}
}
	.link {
		cursor: pointer;
		position: relative;
		min-height: 130px;
		transition: all .4s linear;
		margin-bottom: 51px;

		&:last-child {
			margin-bottom: 0;
		}

		h3 {
			max-width: 100%;
			display: flex;
			font-family: "IBM Plex Mono", monospace;
			font-weight: 200;
			line-height: normal;
			text-align: center;
			transition: all 0.5s ease-in-out;
			font-size: 90px;
			font-size:72px;
			justify-content: center;
			align-items: center;
		}



	

		span {
			transition: .3s linear;
		}

		.normal {
			transform: scale(1);
			opacity: 1;
		}

		.hover {
			transform: scale(.9);
			opacity: 0;
		}

		&:hover {
			.normal {
				transform: scale(.5);
				opacity: 0;
			}

			.hover {
				transform: scale(1);
				opacity: 1;
			}
		}

	}

	.inactive {
		opacity: 0.1;
	}


	.linked {
		&::before {
			width: 361px;
		}
	}

	a {
		font-family: "IBM Plex Mono", monospace;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 50px;
		color: #000000;
/*		-webkit-mix-blend-mode: difference;
		-moz-mix-blend-mode: difference;
		-o-mix-blend-mode: difference;
		-ms-mix-blend-mode: difference;
		mix-blend-mode: difference;*/
		// will-change: opacity;
		width: 100%;
		transition: all 0.3s ease-in-out;

	}


}

.row-title {
	position: relative;
	mix-blend-mode: difference;
	z-index: 99;

	// h5 {
	// 	display: flex;
	// 	justify-content: center;
	// 	width: 100%;
	// 	position: fixed;
	// 	// position: -webkit-sticky;
	// }

	padding-top: 176px;

	h5,
	h4 {
		font-size: 16px;
		font-weight: normal;
		line-height: 21px;
		letter-spacing: 0.275em;
		text-align: center;
		font-family: "IBM Plex Mono", monospace;
		
		margin-bottom: 3%;

		
		// padding-top: 176px;
		color: #fff;
		// margin-bottom: 0;
	}

	h4 {
		margin-top: 200px;
		margin-bottom: 73px;
	}
}

.social-links {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 230px;

	a {
		font-family: "IBM Plex Mono", monospace;
		font-size: 70px;
		font-size:56px;
		font-weight: 200;
		line-height: 1.03;
		text-align: center;
		color: $main-color;
		margin-bottom: 36px;

		:hover {
			color: $main-color;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}


@media (max-width: 1300px) {
	.contact .link h3 {
		font-size: 83px;
		font-size:67px;
	}
}


@media (max-width: 1100px) {

	.row-title {
		position: sticky;
		top: 250px;
		padding-top: 0;

		// padding-top: 250px;
		h5 {
			margin-bottom: 69px;
			// padding-top: 250px;
		}

		h4 {
			margin-bottom: 94px;
		}
	}

	.contact {
		margin-bottom: 100px;
		// margin-top: 69px;
		margin-top: 250px;

		&-desktop {
			display: none;
		}

		&-mobile {
			position: relative;
			display: block;
		}

		&-bg_block {
			display: block;
			text-align: center;
			margin: auto;
			position: sticky;
			top: 340px;
			max-width: max-content;

			img {
				max-width: 100%;
			}

			:first-child {
				opacity: .5;
			}

			:last-child {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.link {
			margin-top: 226px;
			margin-bottom: 0;

			&:first-child {
				margin-top: -231px;
				padding: 0 65px;
			}

			h3 {
				display: flex;
				flex-direction: column;
				opacity: 1;
				font-size: 80px;
				font-size:68px;

				.hover {
					font-size: 16px;
					line-height: 30px;
					margin-bottom: 39px;
				}

				.normal {
					line-height: 104px;
					margin-bottom: 39px;

				}

				.hover,
				.normal {
					opacity: 1;
					transform: scale(1);
				}
			}

			.inactive {
				opacity: 1;
			}

			.hover {
				display: flex;
				font-size: 16px;
				line-height: 30px;
				margin-bottom: 39px;
			}

			&:hover {

				.normal,
				.hover {
					opacity: 1;
					transform: scale(1);
				}

			}

			&::before {
				width: 261px;
			}
		}

		.linked {
			&::before {
				width: 161px;
			}
		}
	}

	.social-links {
		margin-bottom: 280px;
	}



}

@media (max-width: 768px) {
	.contact {

		&-bg {
			display: none;
		}

		.link {

			h3.normal {
				font-size: 80px;
				font-size:67px;
				line-height: 104px;
			}

			&:first-child {
				padding: 0;
			}

		}

	}

	.row-title {
		top: 250px;

		// padding-top: 250px;
		h5 {
			margin-bottom: 45px;
			// padding-top: 250px;
		}

		h4 {
			margin-bottom: 54px;
		}
	}

	.social-links {
		a {
			font-size: 40px;
			font-size:35px;

		}
	}
}


@media (max-width: 576px) {
	.row-title {
		top: 150px;

		// padding-top: 150px;
		h5 {
			margin-bottom: 45px;
			// padding-top: 150px;
		}

		h4 {
			margin-bottom: 54px;
		}
	}


	.contact {
		margin-top: 67%;

		&-bg_block {
			top: 250px;
		}

		.link {
			&:first-child {
				margin-top: -150px;
			}

			margin-top: 120px;

			&:first-child {
				padding: 0;
			}

			h3 {
				.normal {
					font-size: 52px;
					font-size:45px;
					line-height: normal;

				}

				.hover,
				.normal {
					margin-bottom: 35px;
				}
			}

			&::before {
				height: 3px;
			}
		}
	}
}