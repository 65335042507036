/* ------------------------------------------------------------- *
 * Smooth Scrollbar
 * Source: https://github.com/idiotWu/smooth-scrollbar/
/* ------------------------------------------------------------- */

/* Hide default scrollbar */
body:not(.is-mobile).tt-smooth-scroll {
	overflow: hidden;
}

/* Scroll content (added if <body> contain class "tt-smooth-scroll". Disabled on mobile devices!) */
body:not(.is-mobile).tt-smooth-scroll #scroll-container {
	position: relative;
	width: auto;
	height: 100vh;
	overflow: auto;
	margin: 0;
	background-color: #f8f8f8;
}

/* Styling scrollbar */
.scrollbar-track {
	background: transparent !important;
	z-index: 99999 !important;
}

.scrollbar-track:hover {
	background: rgba(222, 222, 222, 0.7) !important;
}

.scrollbar-thumb {
	background: #000 !important;
	opacity: .7;
	transition: opacity 0.2s ease-in-out;
}

.scrollbar-track:hover .scrollbar-thumb {
	opacity: 1;
}

// progress {
//     position: absolute;
//     top: 50%;
//     right: -20px;
//     -webkit-appearance: none;
//     appearance: none;
//     width: 155px;
//     height: 2px;
//     border-radius: 2px;
//     background-color: #e7e7e7;
//     background: #e7e7e7;
//     transform: rotate(90deg);
//     z-index: 99;
// }
// progress::-webkit-progress-bar {
//     height: 2px;
//     border-radius: 2px;
//     background: #e7e7e7;
// }
// progress::-webkit-progress-value {
//     height: 2px;
//     border-radius: 2px;
//     background: #000;
//     background-attachment: fixed;
// }
// progress::-moz-progress-bar {
//     height: 2px;
//     border-radius: 2px;
//     background: #000;
//     background-attachment: fixed;
// }

/* ------------------------------------------------------------- *
 * Magic cursor
/* ------------------------------------------------------------- */

#magic-cursor {
	position: absolute;
	display: none;
	width: 220px;
	height: 220px;
	max-width: 250px;
	pointer-events: none;
	z-index: 99999;
	opacity: 0;
}

body.tt-magic-cursor #magic-cursor {
	display: block;
}

#ball {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 220px;
	height: 220px;
	max-width: 250px;
	// color: #FFF;
	pointer-events: none;
	// border-style: solid;
	// border-color: #FFF;
	// border-radius: 50%;
	// background-image: url('../../assets/img/cursor-link.svg');
	background-repeat: no-repeat;
	background-size: contain;
	/* Note: border width handled through JS */
}


/* Ball view 
============= */
#ball .ball-view {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	padding: 0 5px;
	font-size: 14px;
	font-weight: 500;
	color: #222;
	line-height: 1.3;
	text-align: center;
	transform: scale(0);
}

#ball .ball-view i {
	font-size: 19px;
}


/* Ball drag 
============= */
#ball .ball-drag {
	position: absolute;
	display: block;
	width: 100%;
	padding: 0 5px;
	font-size: 15px;
	font-weight: 600;
	color: #FFF;
	line-height: 1.2;
	text-align: center;
	transition: all 0.3s;
}

#ball .ball-drag::before,
#ball .ball-drag::after {
	position: absolute;
	top: 50%;
	margin-top: -5px;
	font-size: 19px;
	color: #FFF;
	height: 10px;
	line-height: 10px;

	/* Font Awesome */
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

#ball .ball-drag::before {
	content: "\f104";
	/* Font Awesome */
	left: 0;
	transform: translate3d(-30px, 0, 0);
	transition: all 0.25s;
}

#ball .ball-drag::after {
	content: "\f105";
	/* Font Awesome */
	right: 0;
	transform: translate3d(30px, 0, 0);
	transition: all 0.25s;
}


/* Ball close 
============== */
#ball.ball-close-enabled {
	opacity: 1 !important;
}

#ball .ball-close {
	position: absolute;
	padding: 0 5px;
	font-size: 14px;
	font-weight: 600;
	color: #FFF;
	line-height: 1;
	text-align: center;
}