/* ------------------------------------------------------------- *
 * Portfolio carousel
/* ------------------------------------------------------------- */

.tt-portfolio-carousel {
	position: relative;
	margin-bottom: 65px;
	overflow: hidden;
}

.tt-portfolio-carousel .swiper-wrapper {
	position: relative;
	display: flex;
	align-items: flex-start;
	padding-top: 80px;
	height: fit-content;
}

// .tt-portfolio-carousel .swiper-container {
// }

.tt-portfolio-carousel .swiper-wrapper.swtr-smooth {
	/* Add transition when windows resize or orientation change */
	transition: transform 0.8s !important;
}

.tt-portfolio-carousel .swiper-slide {
	height: auto;
	background-color: #f8f8f8 !important;
}




@media (min-width: 1025px) {
	.tt-portfolio-carousel .swiper-slide {
		width: auto;
	}
}


/* Portfolio carousel item
=========================== */
.tt-portfolio-carousel-item {
	position: relative;
	display: flex;
	line-height: 0;

}

.project-year,
.project-category {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 9;

	p {
		@extend .text-simple;
		width: max-content;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		margin: 0;
	}
}

.project-year {
	left: -7%;
}

.project-category {
	right: -18%;
}

@media (min-width: 1025px) {
	.tt-portfolio-carousel-item {
		margin-left: 7vw;
		margin-right: 7vw;
	}
}

.tt-pci-image-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 2;
	overflow: hidden;
}

.tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
	position: relative;
}

video.tt-pci-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}


.tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
	height: 440px;
}

.tt-pci-image {
	width: auto;
}

.tt-portfolio-carousel-item .tt-pci-video {
	width: 48vw;
}




/* Portfolio carousel item hover
================================= */
@media (min-width: 1025px) {

	.pci-hover .tt-slide-active .tt-portfolio-carousel-item .tt-pci-image-wrap,
	.pci-hover .tt-slide-active .tt-portfolio-carousel-item .tt-pci-caption-front {
		transition: all 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
	}


	.pci-hover .tt-pci-image {
		transition: all 1s cubic-bezier(0.07, 0.72, 0.29, 0.96);
	}

	.pci-hover .tt-portfolio-carousel-item:hover .tt-pci-image {
		transform: scale(1.05);
	}
}


/* Slide disabled (for non active slides)
================== */
.tt-portfolio-carousel-item {
	transition: all 0.5s ease-in-out;

	.tt-pci-caption {
		opacity: 1;
	}
}

.swiper-slide.tt-pcs-disabled .tt-portfolio-carousel-item {
	cursor: auto;
	pointer-events: none;
	//opacity: .3;
	transform: scale(0.9);
	// .tt-pci-caption{
	// 	opacity: 0;
	// }
}


/* Portfolio carousel item caption
=================================== */
.tt-pci-caption-inner {
	position: absolute;
	min-width: 120%;
	left: 50%;
	top: 27%;
	@media only screen and (max-width: 600px) {
		top:58%;
	}
	z-index: 9;
	transform: translate3d(-50%, -50%, 0);
	mix-blend-mode: difference;
	height: 200px;
	
}

.tt-pci-caption {
	position: relative;
	// background: transparent;
}

.tt-pci-title {
	font-family: "IBM Plex Mono", monospace;
	font-size: 100px;
	font-weight: 200;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff !important;
mix-blend-mode: difference !important;
/*background: linear-gradient(to right, #8fa7a6 20%, #464646 40%, #8fa7a6, #464646 80%);
    text-shadow: 2px 4px 4px rgb(0 0 0 / 20%), 0px -5px 10px rgb(255 255 255 / 15%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 15s linear infinite;
*/
	// filter: invert(1);
	// filter: invert(100%) sepia(9) !important;
	a {
		-webkit-mix-blend-mode: difference;
		-moz-mix-blend-mode: difference;
		-o-mix-blend-mode: difference;
		-ms-mix-blend-mode: difference;
		mix-blend-mode: difference;
		color: #000;
		text-align: center;

		&:hover {
			color: #000;
		}
	}
}

.tt-pci-categories {
	position: relative;
	margin-top: 30px;
}

.tt-pci-category {
	position: relative;
	display: inline-block;
	font-size: calc(13px + 0.1vw);
	color: #000;
	line-height: 1.2;
}

.tt-pci-category~.tt-pci-category {
	margin-left: -4px;
}

.tt-pci-category~.tt-pci-category:not(:empty)::before {
	content: ", ";
}

.tt-pci-category~.tt-pci-category~.tt-pci-category~.tt-pci-category {
	visibility: hidden;
	width: 0;
}

.tt-pci-category~.tt-pci-category~.tt-pci-category~.tt-pci-category~.tt-pci-category {
	display: none;
}

.tt-pci-category+.tt-pci-category+.tt-pci-category+.tt-pci-category::before {
	content: "...";
	margin-left: 5px;
	visibility: visible;
}

// @media (min-width: 1025px) {
// .tt-pci-caption {
// 	left: 50%;
// 	top: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// }

// 	.tt-pci-caption-front {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		width: 100%;
// 		height: 100%;
// 		z-index: 3;
// 		overflow: hidden;
// 		// filter: invert(1);
// 	}

// 	.tt-pci-caption-back {
// 		left: -1px;
// 		z-index: 1;
// 	}
// }



/* Portfolio carousel caption animation
======================================== */
.swiper-slide .tt-pci-caption-inner,
.swiper-slide .tt-pci-categories,
.swiper-slide .project-category,
.swiper-slide .project-year {
	opacity: 0;
	visibility: hidden;
	transition: 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.swiper-slide.swiper-slide-prev .tt-pci-caption-inner,
.swiper-slide.swiper-slide-next .tt-pci-caption-inner,
.swiper-slide.swiper-slide-prev .tt-pci-categories,
.swiper-slide.swiper-slide-next .project-category,
.swiper-slide.swiper-slide-next .project-year {
	transition: all 0.3s ease-out;
}

.swiper-slide.swiper-slide-active .tt-pci-caption-inner {
	opacity: 1;
	visibility: visible;
}

.swiper-slide.swiper-slide-active .tt-pci-categories,
.swiper-slide.swiper-slide-active .project-category,
.swiper-slide.swiper-slide-active .project-year {
	opacity: 1;
	visibility: visible;
	transform: translateX(0), translateY(0);
}

/* Offset */
.swiper-slide.swiper-slide-prev .tt-pci-caption-inner {
	transform: translate3d(100px, -50%, 0) skewX(10deg);
}

.swiper-slide.swiper-slide-prev .project-category,
.swiper-slide.swiper-slide-prev .project-year {
	transform: translate3d(0, -100px, 0) skewY(10deg);
}

.swiper-slide.swiper-slide-next .tt-pci-caption-inner {
	transform: translate3d(100px, -50%, 0) skewX(-10deg);
}

.swiper-slide.swiper-slide-next .project-category,
.swiper-slide.swiper-slide-next .project-year {
	transform: translate3d(0, 100px, 0) skewY(-10deg);
}

.swiper-slide.swiper-slide-prev .tt-pci-categories {
	transform: translate3d(-30px, 0, 0);
}

.swiper-slide.swiper-slide-next .tt-pci-categories {
	transform: translate3d(30px, 0, 0);
}

/* Delay */
.swiper-slide.swiper-slide-active .tt-pci-caption-inner,
.swiper-slide.swiper-slide-active .project-category,
.swiper-slide.swiper-slide-active .project-year {
	transition-delay: 0.6s;
}

.swiper-slide.swiper-slide-active .tt-pci-categories {
	transition-delay: 0.7s;
}

.swiper-slide.tt-slide-active {
	background-color: #f8f8f8 !important;
}

/* Portfolio carousel caption position
======================================= */
/* Position center */
@media (min-width: 1025px) {
	// .pci-caption-center .swiper-slide.tt-slide-active .tt-pci-caption-front {
	// 	width: auto;
	// 	margin-left: -10vw;
	// 	margin-right: -10vw;
	// 	pointer-events: none;
	// }
}

// .pci-caption-center .tt-pci-caption-front {
// 	overflow: visible;
// }

.pci-caption-center .tt-pci-caption {
	top: 50%;
	left: 50%;
	padding: 0 5%;
	text-align: center;
	transform: translate3d(-50%, -50%, 0);
}



.pci-caption-center .tt-pci-caption-back {
	display: none !important;
}




/* Portfolio carousel navigation
================================= */
.tt-pc-navigation {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	left: 50%;
	width: 300px;
	height: 20px;
	// margin-bottom: 20px;
	z-index: 1;
	transform: translate3d(-50%, 0, 0);
	margin-top: 37px;
}


.tt-pc-nav-prev,
.tt-pc-nav-next {
	position: absolute;
	top: 0;
	z-index: 20;
}

.tt-pc-nav-prev {
	left: 0;
}

.tt-pc-nav-next {
	right: 0;
}

.tt-pc-arrow {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: #000;
	cursor: pointer;
	outline: none;
	max-height: 20px;
}

.tt-pc-arrow-prev::after,
.tt-pc-arrow-next::after {
	line-height: 0;
	font-weight: 900;
	display: flex;
	align-items: center;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.tt-pc-arrow-prev::after {
	content: "";
	background-image: url(../../assets/img/slider-arrow.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 111px;
	height: 20px;
	transform: rotate(-180deg);
}

.tt-pc-arrow-next::after {
	content: "";
	background-image: url(../../assets/img/slider-arrow.svg);
	background-repeat: no-repeat;
	background-size: contain;
	width: 111px;
	height: 20px;
}

.tt-pc-arrow.tt-pc-arrow-disabled {
	cursor: auto;
	pointer-events: none;
}

.tt-portfolio-carousel .swiper-button-lock {
	display: none;
}


/* Portfolio carousel pagination
================================= */
.tt-pc-pagination {}


/* Portfolio carousel pagination bullets */
.tt-pc-pagination-bullets {
	font-size: 0;
}

.tt-pc-pagination-bullets .swiper-pagination-bullet {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: transparent;
	margin: 8px;
	opacity: 1;
	border-radius: 100%;
	border: 2px solid #000;
}

.tt-pc-pagination-bullets .swiper-pagination-bullet-active {
	background: #000;
	opacity: 1;
}

/* Portfolio carousel pagination dynamic bullets */
.tt-pc-pagination-bullets-dynamic {
	position: absolute;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	white-space: nowrap;
	overflow: hidden;
	font-size: 0;
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet {
	transform: scale(.33);
	position: relative;
	transition: .2s transform, .2s left;
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
	transform: scale(.66);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
	transform: scale(.33);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
	transform: scale(.66);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
	transform: scale(.33);
}

.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
	transform: scale(1);
}

.tt-pc-pagination-fraction {
	position: relative;
	font-size: 14px;
	font-weight: 600;
	color: #000;
	line-height: 1;
}



.tt-pc-pagination-progressbar {
	position: absolute;
	left: 70px;
	right: 70px;
	width: auto;
	max-width: 100%;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.3);
}

.tt-pc-pagination-progressbar .swiper-pagination-progressbar-fill {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #FFF;
	transform: scale(0);
	transform-origin: left top;
}





@media (max-width: 1439px) {
	.project-year {
		left: -6%;
	}

	.project-category {
		right: -12%;
	}
}


@media (max-width: 1100px) {
	.tt-pci-title {
		font-size: 65px;
		max-width: 110%;
	}



	.tt-pci-caption-back {
		display: none;
	}

	.tt-pci-caption-front {
		// z-index: 9;
		display: none;
	}

	.tt-portfolio-carousel {
		margin-bottom: 260px;

		.swiper-slide {
			display: flex;
			justify-content: center;
			flex-direction: column;
			width: 75%;
			overflow: visible;
		}
	}

	.project {
		display: flex;
		flex-direction: column;
		position: relative;
		min-width: fit-content;
		width: 100%;
		margin-top: 30px;

		&-category,
		&-year {
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			transform: translate(0);

		}

		&-year {
			margin-bottom: 25px;
		}
	}

	.tt-pc-navigation {
		position: absolute;
		width: 60%;
		bottom: 15%;
		margin-top: 0;
	}

	.tt-portfolio-carousel .swiper-wrapper {
		height: 65vh;
	}

}

@media (max-width: 768px) {
	.tt-portfolio-carousel .swiper-wrapper {
		height: 50vh;
	}

	.tt-portfolio-carousel {
		margin-bottom: 180px;
	}

	.tt-pci-title {
		$min_font: 40px;
		$max_font: 70px;
		@include fluid-type($min_width, $max_width, $min_font, $max_font);
	}

	.tt-portfolio-carousel .swiper-slide {
		width: 70%;
		// padding: 50px;
	}

	.tt-pc-navigation {
		bottom: 16%;
		width: 90%;
	}

	.tt-pc-arrow-next:after,
	.tt-pc-arrow-prev:after {
		width: 80px;
	}

	.tt-pci-image,
	.tt-portfolio-carousel-item .tt-pci-video {
		height: 40vw;
	}
}

@media (max-width: 576px) {
	.tt-portfolio-carousel {
		margin-bottom: 80px;
	}

	// .tt-portfolio-carousel .swiper-wrapper {
	// 	height: 40vh;
	// }

	.tt-pc-navigation {
		bottom: 16%;
	}

}



/// swiper mobile mix blend fixer
 .tt-portfolio-carousel
 {
	.swiper-slide{
			
		margin-top: -60px;
		padding-top: 60px;
		
	}
	.tt-pci-caption-inner {
		margin-top:-102px;
	}
 }
@media (max-width: 770px) {
	.tt-portfolio-carousel {
		.swiper-wrapper{
			width:100%;
			padding-top:100px;
			
			position: relative;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			transition-property: transform;
			box-sizing: content-box;
		
		}
		 .swiper-slide{
			
			margin-top: -70px;
			padding-top: 70px;
			
		}
	
.tt-pci-caption-inner
{	min-width: fit-content;
    max-width: 100%;
	width:100%;
	margin-top: -9%;
}
.tt-pci-title {
    font-size: 33px;
    line-height: normal;
	margin-top: -3px;
}
.swiper {
	width:100%;
}
.swiper-slide {
	width: 73% !important;
  }
/*
  .swiper-slide:nth-child(2n) {
	width: 20% !important;
  }

  .swiper-slide:nth-child(3n) {
	width: 20% !important;
  }
  */
  }

}

