$black: #000000;
$white: #FFFFFF;
$dark-gray: #E5E5E5;
$light-gray: #f8f8f8;
$main-color: #000;
$gotham: 'Gotham';
$min_width: 320px;
$max_width: 768px;

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
  }

  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
	  & {
		font-size: $min-font-size;
		@media screen and (min-width: $min-vw) {
			font-size: 35px;
			line-height: 36px;
		}
		@media screen and (min-width: $max-vw) {
		  font-size: $max-font-size;
		  line-height: normal;
		}
	  }
	}
  }





a {
	font-family: neusa-next-std, sans-serif;
	text-decoration: none !important;
}

// .bordered {
// 	border: 1px solid $black;
// 	border-radius: 900px;
// }

button:focus,
input:focus,
select:focus {
	outline: none;
}

// .button {
// 	width: fit-content;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	padding: 5px 10px 5px 20px;
// 	border-radius: 900px;
// 	border: 1px solid $black;
// 	height: 40px;
// 	cursor: pointer;
// 	background: $white;
// 	@extend .text-button;
// }


.text-simple {
	font-family: neusa-next-std, sans-serif;
	font-size: 16px;
	line-height: 1;
	letter-spacing: normal;
	color: #000;
}

// .text-button {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 15px;
// 	line-height: 20px;
// 	letter-spacing: -0.03em;
// 	color: $black;
// }

// .text-footer-nav {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 16px;
// 	line-height: 20px;
// 	letter-spacing: -0.04em;
// 	color: $black;
// }

// .text-subtitle {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 16px;
// 	line-height: 25px;
// 	letter-spacing: -0.02em;
// 	color: #353535;
// }

// .text-copyright {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 12px;
// 	line-height: 15px;
// 	letter-spacing: -0.02em;
// 	color: $black;
// }

// .text-h3 {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 60px;
// 	line-height: 75px;
// 	letter-spacing: -0.05em;
// 	color: $black;
// }

// .text-h5 {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 30px;
// 	line-height: 40px;
// 	letter-spacing: -0.03em;
// 	color: $white;
// }

// .text-input {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 10px;
// 	line-height: 15px;
// 	letter-spacing: -0.02em;
// 	color: #D5D5D5;
// }

// .text-placeholder {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 12px;
// 	line-height: 15px;
// 	letter-spacing: -0.02em;
// 	color: $light-gray;
// }

// .text-input-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 13px;
// 	line-height: 15px;
// 	letter-spacing: -0.03em;
// 	color: $white;
// }

// .text-header-subtitle {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 18px;
// 	line-height: 30px;
// 	letter-spacing: -0.03em;
// 	color: $white;
// }

// .text-block-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 45px;
// 	line-height: 60px;
// 	letter-spacing: -0.05em;
// 	color: $black;
// }

// .text-tab-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 25px;
// 	line-height: 35px;
// 	letter-spacing: -0.04em;
// 	color: $black;
// }

// .text-map-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 35px;
// 	line-height: 45px;
// 	text-align: center;
// 	letter-spacing: -0.05em;
// 	color: $black;
// }

// .text-slider-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 20px;
// 	line-height: 25px;
// 	letter-spacing: -0.04em;
// 	color: $black;
// }

// .text-avatar-title {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 18px;
// 	line-height: 25px;
// 	letter-spacing: -0.04em;
// 	color: $black;
// }

// .text-toptitle {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 12px;
// 	line-height: 15px;
// 	letter-spacing: -0.02em;
// 	color: $gray;
// }

// .text-img {
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 40px;
// 	line-height: 50px;
// 	letter-spacing: -0.05em;
// 	color: $white;
// }