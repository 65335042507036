.block {
	padding-bottom: 100px;

	&-row {
		background: #f8f8f8;
		padding-bottom: 5px;

		p {
			color: #000000;
		}
	}

	&-title {
		flex-direction: column;
		align-items: center;
		mix-blend-mode: difference;

		p {
			font-family: 'IBM Plex Mono', monospace;
			$min_font: 30px;
			$max_font: 100px;
			@include fluid-type($min_width, $max_width, $min_font, $max_font);
			font-weight: 200;
			line-height: 130px;
			color: #fff;
			margin-bottom: 0;

		}

		margin-bottom: 50px;
	}

	&-desc {
		//mix-blend-mode: difference;
		font-family: neusa-next-std, sans-serif;
		font-size: 16px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: 30px;
		text-align: left;
		color: #000000;
		margin-bottom: 48px;
	}

	&-button {
		text-transform: inherit;
		font-family: neusa-next-std, sans-serif;
		font-size: 16px;
		font-weight: 300;
		line-height: 1;
		letter-spacing: normal;
		text-align: right;
		color: #fff;
		padding: 4px 0;

		&:hover {
			color: #fff;
		}
	}

	.active::before {
		transform-origin: 0% -50%;
		transform: scale3d(1, 2, 1);
	}
}


@media (max-width: 1100px) {
	.block {
		&-title {
			p {
				$min_font: 40px;
				$max_font: 80px;
				@include fluid-type($min_width, $max_width, $min_font, $max_font);
				line-height: 104px;
			}
		}

		&-desc {
			margin-bottom: 37px;

			p {
				line-height: 24px;
			}
		}

		.active::before {
			transform: scale3d(0, 2, 1);
		}
	}
}


@media (max-width: 575.98px) {
	.block {
		padding-bottom: 20px;

		&-title {
			p {
				line-height: 60px;
			}
		}

		&-desc {
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 35px;
		}

		&-button {
			font-size: 14px;
		}
	}
}