#tt-header.tt-header-fixed {
	position: fixed;
}

/* header inner */
.tt-header-inner {
	width: 100%;
	display: flex;
	padding-top: 40px;
	align-items: center;
}



header {
	
	.logo,.metis {
		opacity:0;
	}
	position: relative;
	margin-bottom: 80px;
	// background: transparent;
	z-index: -1;
}

.header {
	// box-shadow: 0px .2px 15px 0px rgba(0, 0, 0, 0.1);
	width: 100%;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	// height: 95px;
	transition: all .8s ease-in-out;
	// filter: invert(1);
	


	.header-top {
		width: 100%;
		padding: 43px 49px;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// height: 95px;
		// z-index: 2;
	}

	.logo {
		// filter: invert(1);

	}
}

// .shadow {
// 	background-color: transparent !important;
// 	box-shadow: none !important;
// }

.metis {
	cursor: pointer;
	position: relative;
	white-space: nowrap;
//	mix-blend-mode: difference;
color:#000000 !important;
}

.metis::before,
.metis::after {
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #000000;
	top: 100%;
	left: 0;
	pointer-events: none;
}

.metis::before {
	content: "";
	transform-origin: 100% 50%;
	transform: scale3d(0, 2, 1);
	transition: transform 0.3s;
}

.metis:hover::before {
	transform-origin: 0% 50%;
	transform: scale3d(1, 2, 1);
}

.header-top .nav {
	display: flex;
	justify-content: space-between;
	align-items: center;


	.nav-item {
		padding: 5px 21px !important;

		&:last-child {
			padding-right: 0 !important;
		}

		&:first-child {
			padding-left: 0 !important;
		}

		.active::before {
			transform-origin: 0% -50%;
			transform: scale3d(1, 2, 1);
		}

		.nav-link {
			padding: 4px 0 !important;
			@extend .text-simple;
			font-family: neusa-next-std, sans-serif;
			color: #000000;
			
		}
	}
}

.header .lang {
	margin-left: 120px;

	.nav-item {
		padding: 0 16px 0px 16px !important;
	}
}


@media (max-width: 1100px) {
	.header {
		.header-top {
			padding: 58px 70px;
		}
	}
}

@media (max-width: 768px) {
	.header {
		.header-top {
			padding: 60px 50px;
		}
	}
}

.filter-invert{
	filter: invert(1) !important;
}