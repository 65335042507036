.nav-link {
    display: flex;
}

.agency-subtitle {
    color: #fff !important;
    mix-blend-mode: difference !important;
}

.text-animation-letter {
    display: inline-block;
}

.block-button.active.metis {
    padding: 11px 0;
}

/* ------------------------------------------------------------- *
 * Page transitions
/* ------------------------------------------------------------- */

#page-transition {
    display: none;
}

body.tt-transition #page-transition {
    position: relative;
    display: block;
    z-index: 99999;
}

/* Transition overlay */
body.tt-transition .ptr-overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0a0a0a;
    z-index: 1;
}

/* Transition preloader */
body.tt-transition .ptr-preloader {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

body.tt-transition .ptr-prel-content {
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: #af1212;
}

.ptr-prel-image {
    max-height: 96px;
    /* You may need to change the img height to match your logo type! */
    opacity: .2;
    /* You may need to change the opacity as well! */
}


/* ----------------------------------------------------------------------------------------- *
 * Page boxed layout
 * Note: Class "boxed" in <body> tag is connected with class "tt-wrap" in several places!
/* ----------------------------------------------------------------------------------------- */

body.tt-boxed .tt-wrap {
    max-width: 1282px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

body.tt-boxed .tt-wrap .tt-wrap {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}


#content-wrap {
    position: relative;
    overflow: hidden;
}

.tt-pci-image-container.work-list-helper-hover-img {
    bottom: initial;
}

.tt-pci-image-container {
    padding: 391px 34px 0 34px;
    display: none;
    bottom: 0;
    position: absolute;
    z-index: 99999;
    background: white;
    width: 100%;
    height: 100vh;
}

.tt-pci-image-full {
    width: 100%;
    max-width: 100%;
    height: 582px;
    object-fit: cover;
}

.work-detail__head {
    display: none;
}
