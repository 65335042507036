.circle {
  &-wrapper {
    padding-top: 176px;
    position: relative;
    overflow: hidden;
  }

  &-svg {
    width: 1266px;
    height: 1266px;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-content {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: visible;
    //margin-bottom: 205px;
    
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: #000;
      margin-bottom: 73px;
    }

    h1 {
      font-family: "IBM Plex Mono", monospace;
      font-size: 100px;
      line-height: 130px;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      
      margin-bottom: 102px;
    }

    h2 {
      text-align: initial;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: #000000;

    }
  }
}
@keyframes opacityAnim {
  0% {
   opacity:0
  }
  50%{
    opacity:0;
  }

  100% {
      opacity:1;
  }
  }
.philosophie {
  position: relative;
  display: flex;
  justify-content: center;

    opacity: 0;
    animation: opacityAnim 3.3s ease-in-out 1 forwards;

  
 

  .wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    //margin-top: 100px;
    //   top: 50%;
    //   transform: translateY(-50%);
  }
}

.agency {
  display: block;


  &-title {
    text-align: center;
  }


  &-text {
    margin-bottom: 200px;

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: #000;
    }
  }

  &-slider {
    position: relative;
    margin-bottom: 53px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
      height: 550px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 595px;

      span {
        font-size: 16px;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
      }
    }

    &__title {
      //font-family: "Reenie Beanie", cursive;
      font-size: 50px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -2.4px;
      margin-bottom: 20px;
      text-align: center;
      color: #000000;
      font-family: "IBM Plex Mono", monospace;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.88;
      text-align: center;
      margin-bottom: 120px;
      color: #000000;
    }
  }

  &-content {
    padding-top: 100px;

    h4 {
      font-family: "IBM Plex Mono", monospace;
      font-size: 29px;
      font-weight: 300;
      line-height: normal;
      margin: 30px 0;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.88;
      letter-spacing: normal;
      margin-bottom: 0;
      max-width: 243px;
    }

    &__first {
      margin-bottom: 228px;
    }

    &__last {
      margin-top: 10px;
      margin-bottom: 300px;
    }
  }
}

.tablet-visible {
  display: none;
}


@media (max-width: 1100px) {
  .tablet-hidden {
    display: none;
  }

  .tablet-visible {
    display: block;
  }

  .agency {
    &-content {
      width: 70%;

      &__first {
        margin-bottom: 100px;
      }

      &__last {
        margin-top: 100px;
        margin-bottom: 232px;
      }
    }

    &-slider {

      &__subtitle {
        margin-bottom: 126px;
      }
    }

    .circle {
      &-wrapper {
        padding-top: 250px;
      }

      &-svg {
        top: -28px;
        left: 50.5%;
      }

      &-content {
        margin-bottom: 126px;

        p {
          margin-bottom: 109px;
        }

        h1 {
          margin-bottom: 393px;
        }


      }
    }

  }
}

@media (max-width: 992px) {
  .circle {
    &-wrapper {
      padding-top: 250px;
    }

    &-svg {
      width: 110%;
      height: 70%;
      object-fit: contain;
      top: 12%;
      left: 50%;
    }

    &-content {
//      margin-bottom: 168px;

      p {
        margin-bottom: 69px;
      }

      h1 {
        font-size: 75px;
        line-height: 104px;
        margin-bottom: 277px;
      }


    }
  }

  .agency {
    &-slider {
      padding: 0;
      margin-bottom: 76px;
    }

    &-title {
      margin-bottom: 100px;
    }

    &-subtitle {
      margin-bottom: 300px;
    }

    &-text {
      display: flex;
      justify-content: center;
    }

    &-content {
      &__last {
        margin-bottom: 174px;
      }
    }
  }
}

@media (max-width: 900px) {
  .circle {
    &-wrapper {
      padding-top: 180px;
    }

    &-svg {
      height: 53%;

    }

    &-content {
      h1 {
        font-size: 50px;
        line-height: 80px;
        margin-bottom: 180px;
      }

    }
  }

  .agency {
    &-content {
      width: 90%;

      &__last {
        margin-bottom: 120px;
      }
    }

    &-slider {
      padding: 0;
      margin-bottom: 76px;

      &__content {
        max-width: 373px;
      }

      &__title {
        font-size: 58px;
        line-height: 58px;
        letter-spacing: -0.03em;
        margin-bottom: 20px;

      }

      &__subtitle {
        margin-bottom: 137px;
      }
    }

  }
}

@media (max-width: 576px) {
  .agency {
    &-content {
      width: 100%;
    }

    &-slider {
      margin-bottom: 0;

      &__content {
        max-width: 320px;
      }

      &__title {
        font-size: 30px;

      }
    }

    .circle {
      &-content {
        h1 {
          font-size: 45px;
          line-height: 70px;
        }
      }
    }

  }
}

// $min_font: 40px;
// $max_font: 100px;
// @include fluid-type($min_width, $max_width, $min_font, $max_font);