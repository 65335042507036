.back-link {
	font-family: "IBM Plex Mono", monospace;
	justify-content: center;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: $main-color;
	margin-top: 15px;
	margin-bottom: 100px;

	&:hover {
		color: $main-color;
	}
}

#work-detail-page .content {
	//padding-top: 392px;
	padding-top: 14%;
	
}


.design-img {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		&.long-img{
			@media only screen and (min-width: 600px) {
				max-width: 50%;
				margin: auto;
			}
			
		}
	}
}

.video-img {
	height: 748px;
}


.work-detail {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	height: 580px;
	margin: 0;

	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 246px 30px;
		background-color: #fff;
		margin: 0;
		height: 666px;

		img {
			max-width: 100%;
		}
	}


	&__head {
		position: absolute;
		z-index: 55;
		top: -60px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		font-family: 'IBM Plex Mono', monospace;
		font-size: 80px;
		word-break: break-word;
		font-weight: 200;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff !important;
		mix-blend-mode: difference !important;
		@media only screen and (min-width: 600px) {
// word break problem			max-width: 72%;
		}
	}

	&__creating {
		padding-top: 140px;

		p {
			font-family: 'IBM Plex Mono', monospace;
			font-size: 29px;
			line-height: 47px;
			// $min_font: 20px;
			// $max_font: 36px;
			// @include fluid-type($min_width, $max_width, $min_font, $max_font);
			font-weight: 300;
			letter-spacing: normal;
			
			color: #000;
			margin-bottom: 0;

		}
	}

	&__profession {
		margin-top: 23px;
		font-family: neusa-next-std, sans-serif;

		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;

		&-date {
			margin: 0;
			padding-bottom: 44px;
		}

		&-list {
			list-style: none;
			margin: 0;
			padding: 0 0 90.5px 0;
		}

		&-link {
			display: flex;
			align-items: center;

			span {
				font-size: 16px;
				font-weight: normal;
				line-height: 1;
				text-align: right;
				padding-left: 12px;
				color: $main-color;
			}
		}

	}

	&__info {
		padding: 102px 0 200px 0;

		&-subtitle {
			margin: 26px 0 35px 0;
			font-family: 'IBM Plex Mono', monospace;
			font-size: 29px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: $main-color;
		}

		&-description {
			max-width: 260px;
			
			width: 100%;
			font-family: neusa-next-std, sans-serif;

			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 180%;
			letter-spacing: normal;
			text-align: left;
			color: $main-color;
		}
	}

	&__section {
		padding-bottom: 100px;
		@media only screen and (max-width: 700px) {
			padding-bottom: 50px;
			
		}
	}

	&__title {
		margin-bottom: 50px;
		font-family: 'IBM Plex Mono', monospace;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 21px;
		letter-spacing: 0.275em;
		text-align: center;

	}

	&__poster {
		background-color: #fff;
		padding-top: 100px;
		padding-bottom: 100px;
		justify-content: center;
		align-items: center;
		margin: auto 0;

		&-img {
			text-align: center;

			img {
				width: 100%;
				object-fit: contain;
				max-width: 512px;
			}
		}

	}
}


.user {
	&-avatar {
		max-width: 93px;
		border-radius: 50%;
	}

	&-info {
		padding-top: 0;
		padding-bottom: 100px;
		padding-top: 100px;
		justify-content: center;
		align-items: center;

		&__description {
			margin: 30px auto;
			font-family: neusa-next-std, sans-serif;

			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 187%;
			letter-spacing: normal;
			text-align: center;
		}

		&__fullname {
			font-family: 'IBM Plex Mono', monospace;
			font-weight: 300;
			font-size: 26px;
			line-height: 34px;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			margin-bottom: 5px;
		}

		&__profession {
			font-family: neusa-next-std, sans-serif;

			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: 20px;
			line-height: 1.43;
			letter-spacing: normal;
		}
	}
}

.cta {
	background: #fff;
	padding: 210px 0;
	margin-bottom: 100px;
	height: 582px;

	&-col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #000000;

		p {
			font-family: 'IBM Plex Mono', monospace;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 21px;
			letter-spacing: 0.275em;
			text-align: center;
			margin-bottom: 10px;
		}

		h2 {
			font-family: 'IBM Plex Mono', monospace;
			font-weight: 200;
			font-size: 100px;
			line-height: 130px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
		}
	}
}

@media (max-width: 1100px) {
	#work-detail-page .content {
		padding-top: 368px;
	}

	.work-detail {
		&__creating {
			padding-top: 238px;
		}

		&__profession {
			margin-top: 48px;
		}

		&__info {
			padding: 163px 0 200px 0;
		}

		&__poster {
			padding-top: 150px;
			padding-bottom: 150px;
			margin: auto 0;

			&-img {
				img {
					width: 100%;
					object-fit: contain;
					max-width: 333px;
				}
			}
		}
	}

	.video-img {
		height: 532px;
	}
}

@media (max-width: 992px) {
	#work-detail-page .content {
		padding-top: 168px;
	}

	.back-link {
		margin-bottom: 162px;
	}

	.design-img {
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.work-detail {
		height: 300px;
		background-position-x: right;

		&__head {
			font-size: 70px;
			line-height: 94px;
		}


		&-link {
			span {
				font-size: 14px;
			}
		}

		&__title {
			font-size: 14px;
			margin-bottom: 30px;
		}

		&__poster {
			background-color: #fff;
			padding-top: 100px;
			padding-bottom: 100px;

			img {
				width: 100%;
				max-width: 512px;
			}
		}

		&__creating {
			padding-top: 76px;
		}

		&__profession {
			font-size: 14px;
			margin-top: 55px;
		}

		&__info {
			padding: 131px 0 200px 0;
			justify-content: center;
			max-width: 90%;
			margin: 0 auto;

			&-subtitle {
				font-size: 32px;
				margin: 20px 0 30px;
			}

			&-description {
				max-width: 100%;
				font-size: 14px;
				margin-bottom: 100px;
			}
		}
	}

	.user-info {
		padding-top: 50px;
		padding-bottom: 50px;

		&__description {
			line-height: 1.5;
		}

		&__fullname {
			font-size: 22px;
		}
	}

	.cta {
		padding: 120px 0;
		margin-bottom: 142px;

		h2 {
			font-size: 80px;
			line-height: 104px;
		}
	}
}

@media (max-width: 576px) {
	#work-detail-page .content {
		padding-top: 120px;
	}

	.back-link {
		margin-bottom: 120px;

	}

	.work-detail {
		//height: 300px;
		background-position-x: right;
		height:auto;
		aspect-ratio: 600/445;


		&__head {
			font-size: 40px;
			line-height: 50px;
			top: -30px;
		}

		&__creating {
			padding-top: 46px;

			p {
				font-size: 28px;
				line-height: 34px;
			}
		}

		&__info {
			padding: 60px 0 60px;
			max-width: 100%;
		}
	}

	.cta {
		margin-bottom: 80px;
		* {color:black;}

		h2 {
			font-size: 50px;
			line-height: 65px;
		}
	}

}




.video-img {
	position: relative;
	width: 100%;
	cursor: pointer;
}

.video-img .bg-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;

}

.video-img .bt-play {
	height: 89px;
	width: 89px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	opacity: 1;
	transition: all .3s linear;
	mix-blend-mode: difference;

	img {
		width: 100%;
		height: 100%;
	}
}

.video-img.open .bt-play {
	transform: scale(1.3);
	opacity: 0;
}

.video-img.open .bg-video {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all .6s .8s;
	transition: all .6s .8s;
}

.video-img.open .video-container {
	opacity: 1;
	-webkit-transition: all .6s .8s;
	transition: all .6s .8s;
}

.video-container {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	z-index: 1;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#work-detail-page .container-fluid {
	padding:0px 25px !important;
}

.work-detail-bgimg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity:0;

	@media screen and (min-width: 768px) {
		padding:0px 24px;
	
		
	}
	img {
		display:block;
		margin:0 auto;
		@media screen and (max-width: 768px) {
			object-fit: cover;
			height:100%;
		}
	}
}