footer {
	position: relative;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 1px;
}

.footer {
	// padding-left: 40px;
	// padding-right: 40px;
	// position: fixed;
	// bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: neusa-next-std, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 1;
	letter-spacing: normal;
	margin-bottom: 50px;
	// padding: 0 36px;


	.nav-link {
		text-align: right;
		color: $main-color;
		padding: 8px 12px !important;
		// mix-blend-mode: difference;
	}

	.footer-block {
		display: flex;
		align-items: center;

		&~.nav-link {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.social-block {
			display: flex;

			.nav-link:first-child {
				padding-left: 0 !important;
			}

			.nav-link:last-child {
				padding-right: 0 !important;
			}
		}
	}

	&-copyright {
		text-align: left;
		color: #9d9d9d !important;
		margin: 0;
		
		.projects-template &{
			text-align: center;
			display: flex;
			justify-content: center;
			border-top: 1px solid gray;
			padding: 30px 0px;
		}
	}
}
@media (max-width: 1100px) {
	.footer {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media (max-width: 992px) {
	.footer {
		align-items: flex-start;
		flex-direction: column;
		// padding: 0 15px;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 25px;

		.footer-block {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 10px;

		}
	}
}

@media (max-width: 576px) {
	.footer {
		font-size: 14px;

		.nav-link {
			padding: 8px !important;
		}

		.footer-block {
			flex-direction: column;
			margin-bottom: 0;

			&~.nav-link {
				width: 100%;
				justify-content: center;
			}

			.social-block {
				margin-bottom: 15px;
				justify-content: space-around;
				width: 100%;
			}
		}
	}
}















/* ------------------------------------------------------------- *
 * Footer
/* ------------------------------------------------------------- */

#tt-footer {
	position: relative;
	width: 100%;
	z-index: 2;
}

.tt-footer-inner {
	position: relative;
}

/* Footer copyright */
@media (min-width: 1025px) {
	#tt-footer.footer-absolute {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 0;
		pointer-events: none;
	}

	#tt-footer.footer-absolute a {
		pointer-events: initial;
	}

	.tt-footer-inner {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	/* Footer columns */
	.footer-col {
		flex: 1;
		padding: 0 15px;
		margin: 0 5px;
	}

	.footer-col:first-child {
		margin-left: 0;
		padding-left: 0;
	}

	.footer-col:last-child {
		margin-right: 0;
		padding-right: 0;
	}

	.footer-col-inner {}

	/* Align footer column content */
	.footer-col.tt-align-top-center {
		display: flex;
		justify-content: center;
	}

	.footer-col.tt-align-top-right {
		display: flex;
		justify-content: flex-end;
	}

	.footer-col.tt-align-center-left {
		display: flex;
		align-items: center;
	}

	.footer-col.tt-align-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer-col.tt-align-center-right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.footer-col.tt-align-bottom {
		display: flex;
		align-items: flex-end;
	}

	.footer-col.tt-align-bottom-center {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.footer-col.tt-align-bottom-right {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}

	/* Footer social */

}

/* For smaller screens only
============================ */
@media (max-width: 1024px) {

	.tt-footer-inner {
		display: flex;
		flex-direction: column;
	}

	/* Footer columns */
	.footer-col {
		display: block;
		margin-bottom: 10px;
		text-align: center;
	}

	/* Order footer columns */
	.footer-col.order-m-first {
		order: -1;
	}

	.footer-col.order-m-last {
		order: 10;
	}

	.footer-col.order-m-1 {
		order: 1;
	}

	.footer-col.order-m-2 {
		order: 2;
	}

	.footer-col.order-m-3 {
		order: 3;
	}

	.footer-col.order-m-4 {
		order: 4;
	}

	.footer-col.order-m-5 {
		order: 5;
	}

	.footer-col.order-m-6 {
		order: 6;
	}

	.footer-col.order-m-7 {
		order: 7;
	}

	.footer-col.order-m-8 {
		order: 8;
	}

	.footer-col.order-m-9 {
		order: 9;
	}

	.footer-col.order-m-10 {
		order: 10;
	}

	/* Footer social */
	#tt-footer .footer-social {
		margin-bottom: 10px;
	}

	#tt-footer .footer-social .footer-social-text {
		display: none;
	}
}


.projects-template{
	.footer-copyright{
		padding: 30px 0px;
		border-top: 1px solid gray;
	
	}
	.work-detail__section{
		padding-bottom: 0px !important;
	}
}
@media
only screen and (max-width: 1100px)
{
	.projects-template{
		.footer {
			display: flex;
			align-items: center;
			.footer-block{
				justify-content: center;
			}
		}
		.footer-copyright {
			display:block;
			margin: 0 auto;
		}
		
	}
}
