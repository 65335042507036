.works {
	// height: 100vh;
	display: flex;
	justify-content: center;
	position: relative;

	&-title {
		margin-top: 24%;
		margin-bottom: 18px;

		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "IBM Plex Mono", monospace;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 4.4px;
		text-align: center;
		color: #000;


	}

	&-img {
		width: 674px;
		height: 440px;
		position: fixed;
		left: 50%;
		top: 300px;
		// transform: translateX(-50%);
		display: flex;
		// margin-bottom: -500px;
		justify-content: center;
		align-items: center;
		// background-image: url(/assets/img/worklist.jpg);
		background-size: cover;
		// background-color: #000;
		background-repeat: no-repeat;
		background-position: top center;
	}

	&-list {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;

		&__title {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			// padding-top: 100px;
			height: 39vh;
			mix-blend-mode: difference;
			color: #fff;
			z-index: 9;
			// padding: 0 30px;
			max-width: 570px;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "IBM Plex Mono", monospace;
			$min_font: 24px;
			$max_font: 64px;
			@include fluid-type($min_width, $max_width, $min_font, $max_font);
			font-weight: 200;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
			margin-bottom: 130px;
		}
	}
}

#work-list {
	.scroller {
		width: 100%;
		max-height: 53.5vh;
		margin-top: 70px;
		margin-bottom: 167px;
		backdrop-filter: blur(214px);
		background-color: #f8f8f8;
	}

	.scroller section {
		height: 60vh;
		display: flex;
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(214px);
		background-color: #f8f8f8;
	}

	.scroller section.showcase {
		justify-content: center;
		align-items: unset;
		// height: 420vh;
		// height: 3360px;
		overflow-y: hidden;
		position: relative !important;
	}

	.text-wrap {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.image-wrap {
		border: none !important;
		position: relative;
		overflow: visible !important;
		max-width: 674px !important;
		width: 100% !important;
		max-height: 440px !important;
		height: 100% !important;
	}

	.image {
		position: absolute;
		top: 0%;
		right: 0%;
		bottom: 0%;
		left: 0%;
		max-width: 674px;
		width: 100%;
		max-height: 440px;
		height: 100%;
		background-position: center;
		background-size: cover;

		img {
			max-width: 100%;
			object-fit: cover;
			// display: none;
		}
	}

	.pin-spacer {
		position: relative;
		min-width: 280px !important;
		width: 674px !important;
	}

	.image.one {
		background-image: url("../../assets/img/portfolio/carousel/portf-carousel-1.jpeg");
	}

	.image.two {
		background-image: url("https://images.unsplash.com/photo-1519378058457-4c29a0a2efac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=648&q=80");
	}

	.image.three {
		background-image: url("https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
	}

	.image.four {
		background-image: url("../../assets/img/work-list.png");
	}

	.image.five {
		background-image: url("https://images.unsplash.com/photo-1558350315-8aa00e8e4590?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
	}

	.image.six {
		background-image: url("../../assets/img/work-list.png");
	}

	.image.seven {
		background-image: url("https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
	}

	.image.eight {
		background-image: url("../../assets/img/work-list.png");
	}

	.scrollbar-track {
		display: none !important;
		// background-color: transparent !important;
		//  width: 10px !important;
	}

	// .scrollbar-thumb {
	// 	background-color: green !important;
	//   margin-right: 2px !important;
	// }

	// .scrollbar-track:hover {
	//   cursor: pointer !important;
	// }

	// .scrollbar-thumb:hover {
	//   cursor: grab !important;
	// }

	// .scrollbar-thumb:active {
	//   cursor: grabbing !important;
	// }
}

@media (max-width: 1200px) {
	.works {
		&-list {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@media (max-width: 992px) {
	#work-list {
		.scroller {
			max-height: 42vh;
			width: 100%;
		}

		.scroller section.showcase {
			height: 740vh;
		}

		.image {
			max-width: 400px;
			max-height: 250px;
		}

		.pin-spacer {
			min-width: 400px !important;
			width: 400px !important;
		}
	}

	.works {
		&-title {
			padding: 0;

			a {
				margin-bottom: 150px;
			}
		}

		&-img {
			width: 450px;
			height: 250px;
		}
	}
}

@media (max-width: 757.98px) {
	.works {
		&-img {
			width: 300px;
			height: 180px;
		}
	}

	#work-list {
		.image {
			max-width: 250px;
			max-height: 150px;
		}

		.pin-spacer {
			min-width: 250px !important;
			width: 250px !important;
		}
	}
}