
// // .slider .container{
// // 	overflow-x: hidden;
// // }
// // .hrz-container {
// // 	width: 100vw;
// // 	height: 100vh;
// // 	display: flex;
// // 	flex-wrap: nowrap;
// // 	background-color: blue;
// // 	padding: 10px;
// // }

// // .hrz-container hrz2 {
// // 	background-color: red;
// // }

// // .vert-container {
// // 	width: 100vw;
// // 	flex-flow: row;
// // 	align-items: center;
// // 	justify-content: center;
// // 	background-color: green;
// // }

// // .vert-container p {
// // 	width: 100vw;
// // 	background-color: greenyellow;
// // }

// // section {
// // 	height: 100vh;
// // 	display: flex;
// // 	flex-wrap: wrap;
// // 	flex-shrink: 0;
// // 	border: 1px solid #000;
// // }

// // .vert-container section {
// // 	width: 100vw;
// // }

// // .s1 p {
// // 	width: 900px;
// // 	background-color: red;
// // }

// // .s2 p {
// // 	width: 1200px;
// // 	background-color: yellow;
// // }

// // .s3 p {
// // 	width: 1500px;
// // 	background-color: rgb(43, 255, 0);
// // }



















		  
// .slides {
// 	height: 500px;
// 	width: 100%;
// 	position: relative;
//   background-color: #222;
//   padding: 0px;
//   margin: 0px;
//   overflow: hidden;
// 	font-size: 62.5%;
// }
		  
// .full-panel {
//     position: relative;
//     left: 50%;
//     min-width: 100%;
//     min-height: 100%;
//     -webkit-transform: translateX(-50%);
//     transform: translateX(-50%);
//     overflow: hidden;
//     min-height: 100vh;
//     clear: both;
// }
		  
// h2 {
// 	font-size: 3em;
// }
		  
// #prevtBtn,#nextBtn{
//   color: white;
//   font-size: 45px;
//   margin: 15px 15px 0px 15px;
//   position: relative;
// }

// .box {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   text-align: center;
//   font-size: 40px;
//   color: white;
//   font-family:'Oswald', arial; 
//   top:0px;
//   left:0px;
// }
// .slide .sideDetails{
//   display:block;
//   position:absolute;
//    top:0;
// 	height: 100%;
// 	width: 50%;
// }
		  
// .detailsText{
// 	position:absolute;
//   -webkit-transform:translateY(-50%);
//   transform:translateY(-50%);
//   top:50%;
// 	margin-left: 150px;
// }
		  
		  
//   .detailsText ul li{
// 	  list-style: none;
// 	  font-family: din-condensed,Helvetica Neue,Arial,sans-serif;
// 	  text-transform: uppercase;
// 	  text-align: left;
	 
// 	  line-height: 1;
//   }


//  .box2 .detailsText ul li{

// 	color: #66CC99;
//   }

//   .box1 .detailsText ul li{
// 	color: #66CCFF;
//   }

//   .detailsText h2{
// 	  text-align: left;
//   }

//   .currentSlide .sideDetails{
// 	  z-index: 5;
//   }		  
		  

// .box.box1 {
  
// }
// .box.box2 {
//   background-color: lightgreen;
// }
// .box.box3 {
//   background-color: yellow;
// }
// .box.box4 {
//   background-color: orange;
// }
// .box.box5 {
//   background-color: red;
// }
		  
// .side1{
// 	background-color: #2d83aa;
// }
// .side2{
// 	background-color: #219382;
// }
// .side3{
// 	background-color: black;
// }
// .side4{
// 	background-color: #219382;
// }
// .side5{
// 	background-color: #2d83aa;
// }

// #logo {
//   border-radius: 50%; 
//   position:absolute;
//   z-index:5000;
//   top:20px; left:20px;
// }


// #links{
// 	z-index:500;
// 	position:absolute;
// 	bottom:0px;
// 	left:0px;
// 	width:100%;
// 	height:50px;
// 	font-size:14px;
// 	font-family:tahoma;
// 	color:#fff;
// }
// #links a{
// 	text-decoration:none;
// 	font-size:2.3em;
// 	color:#fff;
// }
// #twitter{
// 	position:absolute;
// 	bottom:15px;
// 	right:20px;
// }
		  
// #pens{position:absolute;
// 	bottom:15px;
// 	left:20px;
// }



.scroll-slider {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	height: 100vh;
  }
  
  .scroll-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	height: 100%;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
  }
  
  .scroll-slide {
	flex: 1 0 70vw;
	max-width: 70vw;
	height: 100%;
  }
  
  .item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	&-container {
	   position: relative;
	   display: flex;
	   align-items: center;
	}
	&-title {
	//   font-size: 4vw;
	//   font-weight: bold;
	//   line-height: 1;
	}
	&-body {
		margin-top: -110px;
		@media (min-width:40em) {
		position: relative;
		// left: 50%;
		// top: 50%;
		// width: 50%;
		// transform: translate(-25%, -50%);
	  }
	}
  }
  
  .tag {
	margin-right: .5rem;
	> a {
	  display: block;
	  padding: .25rem .5rem;
	  font-size: .75rem;
	  text-transform: uppercase;
	  background: rgba(#FFFFFF, .25);
	  color: #FFF;
	}
  }