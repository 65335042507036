body {
	-webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
	from {
		padding: 0;
	}

	to {
		padding: 0;
	}
}

nav#ddfullscreenmenu {
	
	@media screen and (min-width: 1020px) {
		display:none !important;
	
		
	}
	display:none ;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	visibility: hidden;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	backdrop-filter: blur(214px);
	background-color: #f8f8f8;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
	overflow: hidden;
	overflow-y: auto;
	text-align: center;
	-webkit-transition: visibility 0s 0.5s, opacity 0.5s, -webkit-transform 0.5s;
	transition: visibility 0s 0.5s, opacity 0.5s, transform 0.5s;

	&.openmenu {
		height: 100vh;
		visibility: visible;
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		-webkit-transition-delay: 0s;
		-transition-delay: 0s;
	}

	.footer {
		padding-left: 50px;
		padding-right: 50px;
	}
}

#navtoggler,
#closex {
	display: flex;
	// justify-content: center;
	// align-items: center;
	max-width: 76px;
	max-height: 76px;
	position: absolute;
	cursor: pointer;
	z-index: 15;
	right: 75px;
	top: 65px;

}

#logo {
	position: relative;
	display: flex;
	margin: 60px 50px 0;


	img {
		width: 301px;
	}
}

div#ulwrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	width: 100%;
	text-align: center;
	padding: 0 15px;
	margin-top: 100px;
}

nav#ddfullscreenmenu ul {
	backdrop-filter: blur(214px);
	background-color: #f8f8f8;
	list-style: none;
	margin: 0;
	padding: 50px 0px;
	position: relative;
	width: 100%;
	max-height: 100%;
	font: 500 22px "acumin-pro-wide", sans-serif;
	-webkit-transition: all 0.5s 0.1s;
	transition: all 0.5s 0.1s;

	li {
		margin: 0;
		padding: 15px 0;
		border-radius: 0 !important;
		display: flex;
		justify-content: center;
	}
}

#fullscreenmenu-ul li {
	//mix-blend-mode: difference;

	.active::before {
		transform-origin: 0% 50%;
		transform: scale3d(1, 2, 1);
	}

	a {
		text-decoration: none;
		color: #fff;
		cursor: pointer;
		$min_font: 20px;
		$max_font: 60px;
		font-family: "IBM Plex Mono", monospace;
		@include fluid-type($min_width, $max_width, $min_font, $max_font);
		font-weight: 200;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		//mix-blend-mode: difference;
	}
}

// nav#ddfullscreenmenu .menu-bottom {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	padding: 68px 50px;

// .cont-block {
// 	display: flex;
// 	width: 100%;
// 	align-items: center;
// 	justify-content: space-between;
// 	margin-bottom: 30px;

// 	a {
// 		font-size: 16px;
// 		font-weight: normal;
// 		line-height: 1;
// 		letter-spacing: normal;
// 		text-align: right;
// 		color: #000;
// 	}
// }

// }

nav#ddfullscreenmenu .menu-copyright {
	font-family: neusa-next-std, sans-serif;

	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #9d9d9d;
	margin-bottom: 0;
}

nav#ddfullscreenmenu .lang {
	.nav-item {
		padding: 0 16px !important;
	}

	li {
		.active::before {
			transform-origin: 0% 50%;
			transform: scale3d(1, 2, 1);
		}

		a {
			font-family: neusa-next-std, sans-serif;
			color: #fff;
			cursor: pointer;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
		}
	}
}

nav#ddfullscreenmenu ul {
	.hidemenu {
		transform: rotateY(30deg) translateZ(-5px) !important;
		opacity: 0;
		pointer-events: none;
	}

	.showmenu {
		transform: translate3d(0, 0, 0) !important;
		opacity: 1 !important;
		pointer-events: auto;
	}
}

nav#ddfullscreenmenu .lang-checkbox {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 45px;

	input {
		appearance: none;
		padding: 0 10px;
	}

	.active {
		color: $black;
	}
}

#footermenu {
	align-items: flex-start;
	flex-direction: column;

	.nav-link {
		padding-left: 0 !important;
	}

	.footer-block {
		width: 100%;
		justify-content: space-between;
	}
}

#closex,
#navtoggler {
	img {
		max-width: 76px;
	}
}



#navtoggler,
#closex {
	right: 71px;
	top: 71px;
}

@media (max-width: 576px) {
	#logo {
		margin: 25px 25px 0;

		img {
			width: 215px;
		}
	}


	#navtoggler,
	#closex {
		right: 25px;
		top: 25px;

		img {
			max-width: 45px;
		}
	}

	div#ulwrapper {
		margin-top: 50px;
	}

	nav#ddfullscreenmenu {
		ul {
			font-size: 16px;
		}

		.lang {
			.nav-link {
				padding: 6px !important;
			}
		}
	}
}