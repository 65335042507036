.main {
	padding-top: 204px;
}

.hero {
	&-title {
		font-family: "IBM Plex Mono", monospace;
		font-size: 100px;
		font-weight: 200;
		line-height: 130px;
		text-align: center;
		margin-bottom: 421px;
		color: #fff;
		mix-blend-mode: difference;
	}

	&-subtitle {
		font-family: "IBM Plex Mono", monospace;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: normal;
		text-align: center;
		line-height: 21px;
		letter-spacing: 4.4px;
		font-size: 16px;
		color: #FFFFFF;
		mix-blend-mode: difference;
		margin-bottom: 60px;
	}
}

.letters {
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	position: absolute;
	top: 120px;
	left: 45%;
	transform: translateX(-50%);

	.letter {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: flex-start;
		z-index: 1;

		img {
			width: 100%;
			max-width: 420px;
			position: relative;
		}

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 650px;
			height: 650px;
			border: 1px solid rgba(0, 0, 0, 0.05);
			border-radius: 50%;
			z-index: -1;
		}
	}

	.q-start {
		margin-right: -50px;
		margin-top: 80px;
	}

	.m-start {
		margin-left: 65px;
		margin-top: 210px;
	}
}

@media (max-width: 1100px) {
	.main {
		padding-top: 300px;
	}

	.hero {
		&-title {
			margin-bottom: 561px;
			padding: 0 50px;
		}
	}

	.letters {
		top: 300px;

		.m-start {
			margin-top: 270px;
		}
	}
}

@media (max-width: 992px) {
	.hero {
		&-title {
			padding: 0;
		}
	}

	.letters {
		left: 50%;

		.letter {
			img {
				max-width: 400px;
			}

			&::before {
				width: 500px;
				height: 500px;
			}
		}

		.q-start {
			margin-right: 0;
		}

		.m-start {
			margin-left: 0;
			margin-top: 150px;
		}
	}
}

@media (max-width: 768px) {
	.main {
		padding-top: 278px;
	}

	.hero {
		&-title {
			font-size: 80px;
			line-height: 104px;
			margin-bottom: 433px;
		}
	}

	.letters {
		.letter {
			img {
				max-width: 250px;
			}

			&::before {
				width: 400px;
				height: 400px;
			}
		}
	}
}

@media (max-width: 576px) {
	.main {
		padding-top: 200px;
	}

	.hero {
		&-title {
			margin-bottom: 100px;
			font-weight: 200;
			font-size: 45px;
			line-height: 60px;

		}
	}

	.letters {
		top: 0;

		.letter {

			&::before {
				width: 220px;
				height: 220px;
			}

			img {
				&::after {
					width: 200px;
					height: 200px;
				}
			}
		}
	}
}
